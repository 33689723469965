import styled from "styled-components";

import { Icon } from "@iconify/react";
import { CommonColor } from "../utility/color";
import { CommonString } from "../utility/constant";

const FooterContainer = styled.div`
  background-color: ${CommonColor.primary_color};
  backdrop-filter: blur(10px);
  padding: ${CommonString.common_padding};
  @media (${CommonString.mobile_screen}) {
    padding: 10px ${CommonString.common_padding_for_mobile};
    margin-top: 20px;
    display: ${(props) => (props.isOpenMenu ? "none" : "block")};
  }
`;

const FooterTitle = styled.h2`
  color: ${CommonColor.white};
`;

const FooterText = styled.p`
  width: max-content;
  font-size: 12px;
  cursor: pointer;
  display: ${(props) => (props.isNotVisible ? "none" : "flex")};
  color: ${(props) =>
    props.isActive ? CommonColor.secondary_color : CommonColor.white};
  font-weight: ${(props) => (props.isActive ? "bold" : "inherit")};
  align-items: center;
  transition: all 0.5s ease-in-out;
  &:hover svg {
    font-size: 15px;
    margin-right: 5px;
  }
  @media (${CommonString.mobile_screen}) {
    &:hover {
      font-weight: normal;
    }
    &:hover svg {
      font-size: 0;
      margin-right: 0px;
    }
  }
`;

const FooterTextArrow = styled(Icon)`
  transition: all 0.5s ease-in-out;
  font-size: 0;
  color: ${CommonColor.white};
`;

const FooterDesc = styled.p`
  font-size: 12px;
  color: ${(props) =>
    props.isActive ? CommonColor.secondary_color : CommonColor.white};
  font-weight: ${(props) => (props.isActive ? "bold" : "inherit")};
`;

const BottomFooterContainer = styled.div`
  background-color: ${CommonColor.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px ${CommonString.common_padding};
  @media (${CommonString.mobile_screen}) {
    padding: 10px ${CommonString.common_padding_for_mobile};
  }
`;

const BottomFooterText = styled.div`
  align-items: center;
  display: flex;
  font-size: 9px;
  color: ${CommonColor.white};
`;

const BottomFooterLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-top: -30px;
  // z-index: 9;
  color: ${CommonColor.primary_color};
  transform: rotate(330deg);
  cursor: pointer;
`;

const CopyRightIcon = styled(Icon)`
  padding-right: 10px;
`;

const FooterIconContainer = styled.div`
  display: flex;
`;

const FooterIcon = styled(Icon)`
  background: ${CommonColor.white};
  padding: 8px;
  margin-right: 25px;
  color: ${CommonColor.primary_color};
  width: 24px;
  height: 24px;
`;

const FooterImg = styled.img`
  width: ${(props) => (props.width !== undefined ? props.width : "64px")};
  margin-right: 30px;
`;

const FooterImgContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
`;
const FooterLogo = styled.img`
  width: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export {
  FooterLogo,
  FooterTextArrow,
  FooterDesc,
  FooterImgContainer,
  FooterImg,
  FooterIconContainer,
  FooterIcon,
  CopyRightIcon,
  BottomFooterLogo,
  BottomFooterText,
  BottomFooterContainer,
  FooterContainer,
  FooterText,
  FooterTitle,
};
