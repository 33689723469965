import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../utility/color";
import { CommonString } from "../utility/constant";

//button
const BlogMainImg = styled.img`
  width: 90%;
  // height: 60vh;
  // margin: 20px;
  // margin-top: 40px;
  border-radius: 25px;
  margin-bottom: 20px;
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    margin-top: 80px;
  }
`;
const BlogText = styled.p`
  text-align: left;
`;
const BlogTextMargin = styled.div`
  margin-top: 40px;
`;
const BlogSubTitle = styled.h3`
  text-align: left;
  color: ${CommonColor.secondary_color};
`;
const BlogTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BlogMainContainer = styled.div`
  padding: ${CommonString.common_padding};
  // background: ${CommonColor.secondary_background_color};
  padding-top: 100px;
  @media (${CommonString.mobile_screen}) {
    padding: ${CommonString.common_padding_for_mobile};
  }
`;
const BlogSubImg = styled.img`
  // width: 90%;
  // border-radius: 15px;
  // margin: 20px 0px;
  // margin-left: ${(props) => (props.marginLeft ? "20px" : 0)};
  // margin-right: ${(props) => (props.marginRight ? "20px" : 0)};
  width: 100%;
  // @media (${CommonString.mobile_screen}) {
  //   width: 100%;
  //   margin: 10px 0;
  // }
`;
const BlogSectionNew = styled.div`
  height: 100%;
  // align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BlogImageContainer = styled.div`
  border-radius: 20px;
  margin: 20px;
  @media (${CommonString.mobile_screen}) {
    margin: 10px 0;
  }
`;
const BlogSubImageContainer = styled.div`
  // border-radius: 20px;
  margin: 20px -20px;
  @media (${CommonString.mobile_screen}) {
    margin: 10px 0;
  }
`;
export {
  BlogTextMargin,
  BlogSubImageContainer,
  BlogImageContainer,
  BlogSectionNew,
  BlogSubImg,
  BlogMainImg,
  BlogText,
  BlogSubTitle,
  BlogTextContainer,
  BlogMainContainer,
};
