import React from "react";
import {
  CardMainContainer,
  ClientSectionContainer,
  CodeCardContainer,
  CodeCardContent,
  CodeCardTitle,
  Title,
} from "../../style/home.style";
import { Span } from "../../style/common.style";
import { Grid } from "@material-ui/core";
import { CodeList } from "../../utility/common.mockdata";
export default function CodeSection() {
  return (
    <ClientSectionContainer>
      <Title>
        <Span>NAICS</Span> Codes
      </Title>
      <CardMainContainer>
        {CodeList.map((data) => (
          <CodeCardContainer>
            <CodeCardTitle>{data.code}</CodeCardTitle>
            <CodeCardContent>{data.title}</CodeCardContent>
          </CodeCardContainer>
        ))}
      </CardMainContainer>
    </ClientSectionContainer>
  );
}
