import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../utility/color";
import { CommonString } from "../utility/constant";

const ContactUsContainer = styled.div`
  margin-top: ${CommonString.common_top_padding};
  padding: ${CommonString.common_padding};
  @media (${CommonString.mobile_screen}) {
    padding: ${CommonString.common_padding_for_mobile};
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  &:hover svg {
    color: ${CommonColor.primary_color};
  }
`;
const SocialIcon = styled(Icon)`
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-right: 20px;
  color: ${CommonColor.light_grey};
  transition: all 0.2s ease-in-out;
`;
const ContactUsImage = styled.img`
  width: 40%;
  margin-bottom: 20px;
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    display: none;
  }
`;
const ContactForm = styled.form`
  // padding-left: 30px;
  margin: 0px -7px;
  @media (${CommonString.mobile_screen}) {
    padding-left: 0px;
    margin-top: 20px;
  }
`;
const FormTitle = styled.h2`
  margin: 10px;
`;

const FormButtonContainer = styled.div`
  margin: -10px 10px;
  @media (${CommonString.mobile_screen}) {
    margin-bottom: 20px;
  }
`;

const FormInput = styled.input`
  border: 1px solid ${CommonColor.input_color};
  background: ${CommonColor.input_color};
  border-radius: 35px;
  padding: 10px 15px;
  width: -webkit-fill-available;
  margin: 7px;
  color: ${CommonColor.primary_color};
  &:focus-visible {
    border: 1px solid ${CommonColor.grey};
    background: ${CommonColor.white};
    outline: beige;
  }
`;

const FormTextarea = styled.textarea`
  background: ${CommonColor.input_color};
  border: 1px solid ${CommonColor.input_color};
  border-radius: 35px;
  backdrop-filter: blur(10px);
  padding: 10px 15px;
  width: -webkit-fill-available;
  margin: 7px;
  height: 120px;
  resize: none;
  color: ${CommonColor.primary_color};
  font-family: Mulish;
  &:focus-visible {
    border: 1px solid ${CommonColor.grey};
    background: ${CommonColor.white};
    outline: beige;
  }
`;
const Label = styled.label`
  color: ${CommonColor.grey};
  margin: 0px 8px !important;
  margin-top: 10px !important;
`;
export {
  Label,
  ContactForm,
  FormTitle,
  ContactUsContainer,
  FlexContainer,
  SocialIcon,
  ContactUsImage,
  FormTextarea,
  FormInput,
  FormButtonContainer,
};
