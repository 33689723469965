const CommonIcon = {
  facebook: "carbon:logo-facebook",
  instagram: "ant-design:instagram-filled",
  skype: "fa:skype",
  twitter: "prime:twitter",
  copyright: "ri:copyright-line",
  facebook_without_background: "ri:facebook-fill",
  menu: "ri:menu-3-line",
  mail: "ic:round-mail-outline",
  call: "ph:phone-call",
  location: "material-symbols:location-on-outline",
  linkedin: "uil:linkedin",
  fill_call: "mingcute:phone-call-fill",
  fill_mail: "material-symbols:mail-rounded",
  close: "mdi:window-close",
  arrow: "ic:round-double-arrow",
  partner_arrow:"material-symbols:arrow-right-alt-sharp",
  redirect: "mi:external-link",
  voip: "fluent:people-checkmark-16-regular",
  enterprise_network_management: "fluent:globe-person-20-regular",
  it_consulting: "fluent-mdl2:market",
  managed_it_services: "ph:shield-check",
  staff_augmentation: "fluent-mdl2:profile-search",
  back_arrow: "mdi:arrow-left-thin",
};

export { CommonIcon };
