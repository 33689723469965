import { Grid } from "@material-ui/core";
import React from "react";
import { Div, Span } from "../../style/common.style";
import {
  CardAndContentContainer,
  CardContent,
  CardForContact,
  CardIcon,
  CardTitle,
  ClientSectionContainer,
  ContactCardContainer,
  ContactUsIcon,
  LinkContainer,
  SmallTitle,
  Title,
} from "../../style/home.style";
import { contactUsContent } from "../../utility/common.mockdata";
import { CommonString } from "../../utility/constant";

export default function ContactUsSection() {
  return (
    <ClientSectionContainer>
      <SmallTitle>Contact Us</SmallTitle>
      <Title>
        Feel Free <Span>to Connect </Span>With Us
      </Title>
      <ContactCardContainer>
        {contactUsContent.map((data, index) => (
          <CardForContact>
            <CardAndContentContainer>
              <CardIcon icon={data.icon} />
              <Div>
                <CardContent>
                  <Span>{data.sub_title}</Span>
                </CardContent>
                <CardTitle>{data.title}</CardTitle>
              </Div>
            </CardAndContentContainer>
            <LinkContainer href={data.link_1} target={CommonString.black}>
              <CardContent>
                {data.subject_line_1}
                <ContactUsIcon icon={data.link_1_icon} />
              </CardContent>
            </LinkContainer>
            <LinkContainer href={data.link_2} target={CommonString.black}>
              <CardContent>
                {data.subject_line_2}
                <ContactUsIcon icon={data.link_2_icon} />
              </CardContent>
            </LinkContainer>
          </CardForContact>
        ))}
      </ContactCardContainer>
    </ClientSectionContainer>
  );
}
