import { useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import CommonHeader from "./component/Header/CommonHeader";
import { useSelector } from "react-redux";
import Footer from "./component/Footer";
import { Body, Div } from "./style/common.style";
import HomePage from "./screen/HomeScreen/HomePage";
import { GetView } from "./utility/function";

function App() {
  const isOpenMenu = useSelector((state) => state.HomeReduxStore.isOpenMenu);
  const menuStatus = useSelector((state) => state.HomeReduxStore.menuStatus);
  return (
    <Body isOpenMenu={isOpenMenu}>
      <CommonHeader />
      {GetView(menuStatus)}
      <Footer />
    </Body>
  );
}

export default App;
