import styled from "styled-components";
import { CommonColor } from "../utility/color";
import { CommonString } from "../utility/constant";
//button
const MainContainer = styled.div`
  padding: 0px ${CommonString.screen_side_padding};
  background-color: ${CommonColor.home_bg_color};
  padding-top: ${(props) => (props.isContactUsScreen ? "45px" : "50px")};
  padding-bottom: ${(props) => (props.isContactUsScreen ? "50px" : "0px")};
  @media (${CommonString.mobile_screen}) {
    padding: 0px ${CommonString.screen_side_padding_for_mobile};
    padding-top: 80px;
    display: ${(props) => (props.isOpenMenu ? "none" : "block")};
  }
`;

const SectionName = styled.p`
  margin-top: ${(props) =>
    props.noMargin || props.isService ? "10px" : "70px"};
  letter-spacing: 0px;
  font-weight: bold;
  text-align: ${(props) =>
    props.textAlign === CommonString.center ? "center" : "left"};
  color: ${CommonColor.primary_color};
  @media (${CommonString.mobile_screen}) {
    margin-top: 20px;
  }
`;
const ServiceContainer = styled.div`
  // background-color: ${CommonColor.light_card_color};
  // backdrop-filter: blur(10px);
  border: 1px solid ${CommonColor.light_grey};
  padding: 20px;
  margin: 10px;
  border-radius: 15px;
  text-align: center;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "130px")};
  transition-timing-function: ease-in-out;
  transition-duration: 400ms;
  color: ${CommonColor.card_text_color};
  &:hover {
    transform: scale(1.03);
    border-radius: 15px;
    background-color: ${CommonColor.secondary_background_color};
    border-color: ${CommonColor.secondary_color};
  }
  @media (${CommonString.mobile_screen}) {
    background-color: ${CommonColor.primary_light};
    margin: 10px 0px;
    min-height: auto;
    border-radius: 10px;
    &:hover {
      transform: scale(1);
      border-radius: 10px;
    }
  }
`;

const ServiceListContainer = styled.ul`
  list-style-position: outside;
  list-style-type: circle;
  margin-block-start: 10px;
  margin-block-end: 10px;
`;

const ServiceList = styled.li`
  text-align: left;
  margin: 10px 0;
`;
const ServiceMainContainer = styled.div`
  padding: ${CommonString.common_padding};
  @media (${CommonString.mobile_screen}) {
    padding: ${CommonString.common_padding_for_mobile};
  }
`;
const ServiceImg = styled.img`
  // height: 60vh;
  width: -webkit-fill-available;
  @media (${CommonString.mobile_screen}) {
    margin-top: 63px;
  }
`;
const ServiceTitleContainer = styled.div`
  display: flex;
  position: absolute;
  top: 30vh;
  color: white;
  z-index: 8;
  align-items: center;
  justify-content: center;
  width: 100vw;
  flex-direction: column;
  &:p {
    width: 70% !important;
  }
  @media (${CommonString.mobile_screen}) {
    // padding: 10px ${CommonString.common_padding_for_mobile};
    position: relative;
    padding: ${CommonString.common_padding_for_mobile};
    top: 0;
    h2 {
      color: ${CommonColor.primary_color} !important;
    }
    p {
      color: ${CommonColor.primary_color} !important;
    }
  }
`;
export {
  ServiceTitleContainer,
  ServiceImg,
  ServiceMainContainer,
  SectionName,
  MainContainer,
  ServiceContainer,
  ServiceListContainer,
  ServiceList,
};
