import { createSlice } from "@reduxjs/toolkit";
import { Service, serviceList } from "../utility/common.mockdata";
import { CommonString } from "../utility/constant";

export const HomeReduxStore = createSlice({
  name: "HomeReduxStore",
  initialState: {
    menuStatus: CommonString.home,
    isButton: false,
    isOpenMenu: false,
    serviceData: Service[0],
    formName: "",
    formEmail: "",
    formContactNumber: "",
    formSubject: "",
    formMessage: "",
  },
  reducers: {
    changeMenuStatus: (state, action) => {
      state.menuStatus = action.payload;
    },
    changeIsButton: (state, action) => {
      state.isButton = action.payload;
    },
    changeIsOpenMenu: (state, action) => {
      state.isOpenMenu = action.payload;
    },
    changeServiceData: (state, action) => {
      state.serviceData = action.payload;
    },
    changeFormName: (state, action) => {
      state.formName = action.payload;
    },
    changeFormEmail: (state, action) => {
      state.formEmail = action.payload;
    },
    changeFormContactNumber: (state, action) => {
      state.formContactNumber = action.payload;
    },
    changeFormSubject: (state, action) => {
      state.formSubject = action.payload;
    },
    changeFormMessage: (state, action) => {
      state.formMessage = action.payload;
    },
  },
});

export const {
  changeFormName,
  changeFormEmail,
  changeFormContactNumber,
  changeFormSubject,
  changeFormMessage,
  changeMenuStatus,
  changeIsButton,
  changeIsOpenMenu,
  changeServiceData,
} = HomeReduxStore.actions;

export default HomeReduxStore.reducer;
