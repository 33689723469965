import { Grid } from "@material-ui/core";
import React, { useRef } from "react";
import { Div, FormSolidButton, Span } from "../../style/common.style";
import {
  ContactForm,
  ContactUsContainer,
  ContactUsImage,
  FlexContainer,
  FormButtonContainer,
  FormInput,
  FormTextarea,
  Label,
  SocialIcon,
} from "../../style/contactUs.style";
import { LinkContainer, CardContent, Title } from "../../style/home.style";
import { contactUsContent } from "../../utility/common.mockdata";
import { CommonString } from "../../utility/constant";
import contact_us_human from "../../assets/images/contact_us.svg";
import { onSubmitContactForm } from "../../utility/function";
import Button from "../../component/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFormContactNumber,
  changeFormEmail,
  changeFormMessage,
  changeFormName,
  changeFormSubject,
} from "../../redux/HomeReduxStore";
import emailjs from "@emailjs/browser";

export default function ContactUsPage() {
  const form = useRef();
  const dispatch = new useDispatch();
  const formName = useSelector((state) => state.HomeReduxStore.formName);
  const formEmail = useSelector((state) => state.HomeReduxStore.formEmail);
  const formContactNumber = useSelector(
    (state) => state.HomeReduxStore.formContactNumber
  );
  const formSubject = useSelector((state) => state.HomeReduxStore.formSubject);
  const formMessage = useSelector((state) => state.HomeReduxStore.formMessage);

  function onSubmitForm(e) {
    e.preventDefault();
    // var data = {
    //   Name: formName,
    //   Email: formEmail,
    //   Contact_Number: formContactNumber,
    //   Subject: formSubject,
    //   Message: formMessage,
    // };
    // console.log(e.target[0].value);
    emailjs
      .sendForm(
        "service_a9sfu3p",
        "template_aaj1qnq",
        form.current,
        "q5vs-OeoirHQwARU3"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  return (
    <ContactUsContainer>
      <Grid container>
        <Grid item md={5} xs={12}>
          <Title align={"left"}>
            Feel free <Span>to connect</Span>
          </Title>
          <ContactForm ref={form} onSubmit={onSubmitForm}>
            <Grid container>
              <Grid md={6} xs={12}>
                <Label>{CommonString.name}</Label>
                <FormInput
                  name="name"
                  onChange={(e) => dispatch(changeFormName(e.target.value))}
                />
                <Label>{CommonString.mobile_number}</Label>
                <FormInput
                  name="number"
                  onChange={(e) =>
                    dispatch(changeFormContactNumber(e.target.value))
                  }
                />
              </Grid>
              <Grid md={6} xs={12}>
                <Label>{CommonString.email}</Label>
                <FormInput
                  name="email"
                  onChange={(e) => dispatch(changeFormEmail(e.target.value))}
                />
                <Label>{CommonString.subject}</Label>
                <FormInput
                  name="subject"
                  onChange={(e) => dispatch(changeFormSubject(e.target.value))}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Label>{CommonString.send_message}</Label>
                <FormTextarea
                  name="message"
                  onChange={(e) => dispatch(changeFormMessage(e.target.value))}
                />
                {/* <FormButtonContainer> */}
                <FormSolidButton type="submit" value="Send" />
                {/* <Button type="submit" value="Send" /> */}
                {/* </FormButtonContainer> */}
              </Grid>
            </Grid>
          </ContactForm>
        </Grid>
        <Grid md={1} xs={12}></Grid>
        <Grid item md={6} xs={12}>
          <ContactUsImage src={contact_us_human} />
          {contactUsContent.map((data) => (
            <FlexContainer>
              <SocialIcon icon={data.link_1_icon} />
              <Div>
                <LinkContainer href={data.link_1} target={CommonString.black}>
                  <CardContent>{data.subject_line_1}</CardContent>
                </LinkContainer>
                <LinkContainer href={data.link_2} target={CommonString.black}>
                  <CardContent>{data.subject_line_2}</CardContent>
                </LinkContainer>
              </Div>
            </FlexContainer>
          ))}
        </Grid>
      </Grid>
    </ContactUsContainer>
  );
}
