import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../utility/color";
import { CommonString } from "../utility/constant";
import { keyframes } from "styled-components";

const breatheAnimation = keyframes`
 0% { height:0vh }
 100% { height: 100vh }
`;

const MenuContainerForMobile = styled.div`
  background: ${CommonColor.primary_color};
  height: 100vh;
  @media (${CommonString.mobile_screen}) {
    display: block;
    z-index: 9;
    position: absolute;
    padding: ${CommonString.common_padding_for_mobile};
    padding-top: 80px;
    animation-name: ${breatheAnimation};
    animation-duration: 0.4s;
    width: 100%;
  }
`;
const SmallText = styled.span`
  font-size: 0.775rem;
  font-weight: 500;
  color: ${CommonColor.small_text_color};
  margin-bottom: 10px;
`;
const MenuItemContainer = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: center;
  @media (${CommonString.mobile_screen}) {
    display: none;
  }
`;
const MenuItem = styled.p`
  color: ${(props) =>
    props.isActive ? CommonColor.secondary_color : CommonColor.white};
  font-size: 16px;
  // font-weight: ${(props) => (props.isActive ? "900" : "300")};
  margin-bottom: 0;
  margin-right: 40px;
  &:hover {
    transition-timing-function: ease-in-out;
    transition-duration: 400ms;
    cursor: pointer;
  }
  display: ${(props) => (props.isDisplayNone ? "none" : "block")};
  @media (${CommonString.mobile_screen}) {
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 2rem !important;
    font-weight: 500;
    line-height: 2.25rem;
    display: block;
  }
`;
const RedirectLinkIcon = styled(Icon)`
  margin-left: 10px;
  color: ${CommonColor.small_text_color};
`;
const StickyIconContainerForMobile = styled.div`
  display: flex;
  justify-content: inherit;
  margin-top: 10px;
  svg {
    background-color: ${CommonColor.transparent};
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
`;

const Divider = styled.hr`
  margin: 40px -40px;
  @media ${CommonString.mobile} {
    margin: 0 0px;
  }
`;
const StickyIcon = styled(Icon)`
  color: ${CommonColor.white};
  width: 20px;
  height: 20px;
  margin-top: 1.5px;
  transition-duration: 0.8s;
`;
export {
  MenuItemContainer,
  MenuContainerForMobile,
  Divider,
  StickyIconContainerForMobile,
  RedirectLinkIcon,
  MenuItem,
  SmallText,
  StickyIcon,
};
