import { Grid } from "@material-ui/core";
import React from "react";
import {
  PartnerCardContainer,
  PartnerContainer,
  PartnerDesc,
  PartnerImage,
  PartnerImageContainer,
  PartnerTitle,
  PartnerViewMoreButton,
  PartnerViewMoreIcon,
} from "../../style/about.style";
import { Span } from "../../style/common.style";
import { LinkContainer, Title } from "../../style/home.style";
import { PartnerList } from "../../utility/common.mockdata";
import { CommonString } from "../../utility/constant";
import { CommonIcon } from "../../utility/icon";

export default function PartnerSection() {
  return (
    <PartnerContainer>
      {/* <SmallTitle>Blog</SmallTitle> */}
      <Title>
        Our <Span>Partners</Span>
      </Title>
      <Grid container>
        {PartnerList.map((data) => (
          <Grid item md={3} xs={12}>
            <LinkContainer href={data.link} target={CommonString.black}>
              <PartnerCardContainer>
                <PartnerImageContainer>
                  <PartnerImage src={data.img} />
                </PartnerImageContainer>
                <PartnerTitle>{data.title}</PartnerTitle>
                <PartnerDesc>{data.desc}</PartnerDesc>
                <PartnerViewMoreButton>
                  Learn More
                  <PartnerViewMoreIcon icon={CommonIcon.partner_arrow} />
                </PartnerViewMoreButton>
              </PartnerCardContainer>
            </LinkContainer>
          </Grid>
        ))}
      </Grid>
    </PartnerContainer>
  );
}
