import Images from "./common.image";
import {
  CommonString,
  ContactUsCard,
  EnterpriseServices,
  ITConsulting,
  ITservices,
  Partner,
  ServiceList,
  SocialLink,
  StaffServices,
  WhyChooseUs,
} from "./constant";
import { CommonIcon } from "./icon";
import img_1 from "../assets/images/partnerImgForSlider/partner_img_1.png";
import img_2 from "../assets/images/partnerImgForSlider/partner_img_2.png";
import img_3 from "../assets/images/partnerImgForSlider/partner_img_3.png";
import img_4 from "../assets/images/partnerImgForSlider/partner_img_4.png";
import img_5 from "../assets/images/partnerImgForSlider/partner_img_5.png";
import img_6 from "../assets/images/partnerImgForSlider/partner_img_6.png";
import img_7 from "../assets/images/partnerImgForSlider/partner_img_7.png";
import img_8 from "../assets/images/partnerImgForSlider/partner_img_8.png";
import img_9 from "../assets/images/partnerImgForSlider/partner_img_9.png";
import img_10 from "../assets/images/partnerImgForSlider/partner_img_10.png";
import partner_img_1 from "../assets/images/color_partner_img_1.png";
import partner_img_2 from "../assets/images/color_partner_img_2.png";
import partner_img_3 from "../assets/images/color_partner_img_3.png";
import partner_img_4 from "../assets/images/color_partner_img_4.png";

export const sliderData = [
  {
    image: Images.sliderImg_1,
    title: "Technology, Consulting, \nProject & Program Management",
    desc: "Olan Associates is a one-stop global IT management and consulting firm that provides organizations with reliable, business-focused IT services and solutions that optimize operations, manage risk and deliver",
  },
  {
    image: Images.sliderImg_2,
    title: "Talent acquisition",
    desc: "Our team of experts has 25+ years of combined experience in placing resources with Government and Commercial clients. ",
  },
  {
    image: Images.sliderImg_3,
    title: "Essential IT support",
    desc: "World class support and maintenance services, catering specifically to those with complex and integrated technology environments.",
  },
  {
    image: Images.sliderImg_4,
    title: "Make a statement on the web!",
    desc: "Transform your business in the digital world. Instead of aligning your business with available technology, let us align technology to your business.",
  },
  {
    image: Images.sliderImg_5,
    title: "Proactive network management",
    desc: "Enjoy the benefits of an Internal Network & Management for a fraction of the price of staffing one. We reduce the business impact of IT failures by minimizing their occurrence",
  },
];
export const sliderDataForMobile = [
  {
    image: Images.sliderImgForMobile_1,
    title: "Preeminent service provider",
    desc: "Olan Associates is a one-stop global IT management and consulting firm that provides organizations with reliable, business-focused IT services and solutions that optimize operations, manage risk and deliver",
  },
  {
    image: Images.sliderImgForMobile_2,
    title: "Talent acquisition",
    desc: "Our team of experts has 25+ years of combined experience in placing resources with Government and Commercial clients. ",
  },
  {
    image: Images.sliderImgForMobile_3,
    title: "Essential IT support",
    desc: "World class support and maintenance services, catering specifically to those with complex and integrated technology environments.",
  },
  {
    image: Images.sliderImgForMobile_4,
    title: "Make a statement on the web!",
    desc: "Transform your business in the digital world. Instead of aligning your business with available technology, let us align technology to your business.",
  },
  {
    image: Images.sliderImgForMobile_5,
    title: "Proactive network management",
    desc: "Enjoy the benefits of an Internal Network & Management for a fraction of the price of staffing one. We reduce the business impact of IT failures by minimizing their occurrence",
  },
];
export const serviceList = [
  {
    title: "Staff Augmentation Services",
    img: Images.service_3,
  },
  {
    title: "Managed IT Services",
    img: Images.service_2,
  },
  {
    title: "IT Consulting Services",
    img: Images.service_4,
  },
  {
    title: "Enterprise Network Management",
    img: Images.service_2,
  },
];

export const BlogList = [
  {
    title:
      "A presentation at the EXIM Bank Washington DC by Dr. Elekwachi, President, Olan Associates LLC.",
    sub_title:
      "The president of Olan Associates, Dr Elekwachi, made a presentation at the just concluded EXIM bank meeting with business owners in Washington DC.",
    blogger_name: "Cristofer Westervelt",
    img: Images.blog_1,
    date: "January 25, 2021",
    time: "5 min Read",
    pageName: CommonString.blog_1,
  },
  {
    title: "Using Blockchain Technology to Boost Cyber Security",
    sub_title:
      "Navigating the online world safely has become a real concern over the last few years and, looking at how intense and sophisticated some of the recent hacker attacks around the world have been, it seems like things are bound to only get worse.",
    blogger_name: "Cristofer Westervelt",
    img: Images.blog_2,

    date: "January 25, 2021",
    time: "5 min Read",
    pageName: CommonString.blog_2,
  },
  {
    title: "How Software Requirements Affect Each Stage of a Project",
    sub_title:
      "When it comes to completing large software projects, one of the most important factors in achieving success is to lay the requirements out clearly. Unfortunately, this is a lot easier said than done. This article will outline a few of the ways that requirements affect development and what happens when they aren’t clear from the onset.",
    img: Images.blog_3,
    blogger_name: "Cristofer Westervelt",
    date: "January 25, 2021",
    time: "5 min Read",
    pageName: CommonString.blog_3,
  },
  {
    title:
      "Olan Associates emerges 2018 CRMSDC Supplier of the year award winner",
    sub_title:
      "Click link below for more information. CRMSDC’S LEADERS AND LEGENDS FEATURING THE TOP 100 MBE AWARDS©",
    img: Images.blog_4,
    blogger_name: "Cristofer Westervelt",
    date: "January 25, 2021",
    time: "5 min Read",
    pageName: CommonString.blog_4,
  },
];
export const contactUsContent = [
  {
    title: ContactUsCard.title_1,
    sub_title: ContactUsCard.sub_title_1,
    subject_line_1: SocialLink.mail_1,
    subject_line_2: SocialLink.mail_2,
    icon: CommonIcon.mail,
    link_1: SocialLink.mail_1_link,
    link_2: SocialLink.mail_2_link,
    link_1_icon: CommonIcon.mail,
    link_2_icon: CommonIcon.mail,
  },
  {
    title: ContactUsCard.title_2,
    sub_title: ContactUsCard.sub_title_2,
    subject_line_1: SocialLink.call_1,
    subject_line_2: SocialLink.call_2,
    icon: CommonIcon.call,
    link_1: SocialLink.call_1_link,
    link_2: SocialLink.call_2_link,
    link_1_icon: CommonIcon.call,
    link_2_icon: CommonIcon.call,
  },
  {
    title: ContactUsCard.title_3,
    sub_title: ContactUsCard.sub_title_3,
    subject_line_1: SocialLink.location_1,
    icon: CommonIcon.location,
    link_1: SocialLink.location_1_link,
    link_1_icon: CommonIcon.location,
  },
];
export const MenuItemList = [
  CommonString.home,
  CommonString.about_us,
  CommonString.our_work,
  // CommonString.our_blog,
  CommonString.contact_us,
];
export const FooterStickyList = [
  {
    icon: CommonIcon.fill_call,
    link: SocialLink.call_1_link,
  },
  {
    icon: CommonIcon.fill_mail,
    link: SocialLink.mail_1_link,
  },
  {
    icon: CommonIcon.facebook,
    link: SocialLink.facebook_link,
  },
  {
    icon: CommonIcon.twitter,
    link: SocialLink.twitter_link,
  },
  {
    icon: CommonIcon.linkedin,
    link: SocialLink.linkedin_link,
  },
];
export const imageList = [
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
  img_6,
  img_7,
  img_8,
  img_9,
  img_10,
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
];
export const Service = [
  // {
  //   menu_status: "Staff Augmentation Services",
  //   service_name: ServiceList.staff_augmentation_services,
  //   service_desc: ServiceList.staff_augmentation_services_desc,
  //   icon: CommonIcon.staff_augmentation,
  //   img: Images.service_5,
  // },
  // {
  //   menu_status: "Staff Augmentation Services",
  //   service_name: ServiceList.staff_augmentation_services,
  //   service_desc: ServiceList.staff_augmentation_services_desc,
  //   icon: CommonIcon.staff_augmentation,
  //   img: Images.service_5,
  // },
  // {
  //   menu_status: "Managed IT Services",
  //   service_name: ServiceList.managed_IT_services,
  //   service_desc: ServiceList.managed_IT_services_desc,
  //   icon: CommonIcon.managed_it_services,
  //   img: Images.service_3,
  // },
  // {
  //   menu_status: "IT Consulting Services",
  //   service_name: ServiceList.IT_consulting_services,
  //   service_desc: ServiceList.IT_consulting_services_desc,
  //   icon: CommonIcon.it_consulting,
  //   img: Images.service_4,
  // },
  // {
  //   menu_status: "Enterprise Network Management",
  //   service_name: ServiceList.enterprise_network_management,
  //   service_desc: ServiceList.enterprise_network_management_desc,
  //   icon: CommonIcon.enterprise_network_management,
  //   img: Images.service_2,
  // },
  {
    menu_status: "Information Technology Consulting Services",
    service_name: "Information Technology Consulting Services",
    service_desc: "Our Information Technology Consulting Services offer comprehensive guidance for businesses, covering strategic IT planning, system implementation, and ongoing support. We specialize in optimizing IT infrastructure, enhancing cybersecurity, and providing tailored solutions to meet the evolving technology needs of your organization.",
    icon: CommonIcon.enterprise_network_management,
    img: Images.service_2,
  },
  {
    menu_status: "Human Resource Consulting",
    service_name: "Human Resource Consulting",
    service_desc: "Our Human Resource Consulting services provide a holistic approach to workforce development. We specialize in crafting customized training programs to elevate your workforce capabilities.",
    icon: CommonIcon.voip,
    img: Images.service_5,
  },
  {
    menu_status: "Management Consulting",
    service_name: "Management Consulting",
    service_desc: "Our Management Consulting services provide strategic guidance to optimize your business processes and achieve organizational goals. We specialize in analyzing current practices, identifying areas for improvement, and implementing effective solutions.",
    icon: CommonIcon.it_consulting,
    img: Images.service_4,
  },
  {
    menu_status: "Staff Augmentation and Other Administrative Services",
    service_name: "Staff Augmentation and Other Administrative Services",
    service_desc: "Our team of experts has 25+ years of combined experience in placing resources with Government and Commercial clients. Sufficient time and resources are allocated for the comprehensive study of the client’s requirements, screening of candidates, recruitment process, and personnel management to deliver the most efficient service currently available in the market.",
    img: Images.service_5,
    icon: CommonIcon.staff_augmentation,
  },
];
export const PartnerList = [
  {
    title: Partner.title_1,
    desc: Partner.title_1_desc,
    img: partner_img_1,
    link: Partner.title_1_link,
  },
  {
    title: Partner.title_2,
    desc: Partner.title_2_desc,
    img: partner_img_2,
    link: Partner.title_2_link,
  },
  {
    title: Partner.title_3,
    desc: Partner.title_3_desc,
    img: partner_img_3,
    link: Partner.title_3_link,
  },
  {
    title: Partner.title_4,
    desc: Partner.title_4_desc,
    img: partner_img_4,
    link: Partner.title_4_link,
  },
];
export const why_choose_us = [
  {
    title: WhyChooseUs.dedication,
    desc: WhyChooseUs.dedication_desc,
  },
  {
    title: WhyChooseUs.efficient,
    desc: WhyChooseUs.efficient_desc,
  },
  {
    title: WhyChooseUs.customer_satisfaction,
    desc: WhyChooseUs.customer_satisfaction_desc,
  },
  {
    title: WhyChooseUs.teamwork,
    desc: WhyChooseUs.teamwork_desc,
  },
];
export const IndividualEnterpriseManagementServices = [
  {
    title: "Project & Program Management ",
    desc: "Our Project & Program Management services excel in ensuring the seamless execution and success of your initiatives. We specialize in meticulous planning, efficient resource allocation, and effective risk management to meet project goals.",
  },
  {
    title: "Workforce Development",
    desc:"Our Workforce Development services focus on nurturing the skills and potential of your employees for enhanced productivity and career growth. We offer tailored programs encompassing training, skill-building, and professional development to align with organizational objectives.",
  },
  {
    title: "Acquisition Support and Training",
    desc: "Our Acquisition Support and Training services are designed to streamline the process of acquiring new assets. We specialize in providing comprehensive support and training programs for your team, ensuring a smooth integration of acquisitions.",
  },
  {
    title: "Contract Officer Training",
    desc: "Our Contract Officer Training services are tailored to empower professionals with the knowledge and skills essential for effective contract management. We specialize in providing comprehensive training programs that cover contract negotiation, compliance, and risk management.",
  },
  {
    title: "Acquisition Planning",
    desc: "Our Acquisition Planning services are dedicated to meticulously crafting strategies for effective procurement and asset acquisition. We specialize in developing comprehensive plans that encompass needs assessment, budget considerations, risk analysis, and vendor selection.",
  },
  {
    title: "Contract Administration",
    desc: "Our Contract Administration services focus on the meticulous oversight and management of contractual agreements throughout their lifecycle. We specialize in ensuring compliance, monitoring performance, and addressing issues to maximize the value of contracts.",
  },
  {
    title:"Contract Closeout",
    desc:"Our Contract Closeout services are designed to facilitate the smooth and systematic conclusion of contractual agreements. We specialize in managing the final stages of contracts, ensuring all obligations are met, and facilitating an orderly transition or closure."
  },
  {
    title:"Price/Cost Analytics",
    desc:"Our Price/Cost Analytics services focus on in-depth analysis and evaluation of pricing and cost structures within your organization. We specialize in leveraging data-driven insights to optimize pricing strategies, enhance cost efficiency, and maximize profitability."
  },
  {
    title:"Acquisition Liaison",
    desc:"Our Acquisition Liaison services act as a strategic bridge between your organization and potential acquisitions. We specialize in facilitating effective communication, fostering collaboration, and ensuring a seamless integration process."
  },
];
export const IndividualITservices = [
  {
    title: "Managed IT Services",
    desc: "Olan Associates' Managed Services (MS) offering provides world class support and maintenance services to enterprise level entities, catering specifically to those with complex and integrated technology environments.",
  },
  {
    title: "Enterprise Network Management",
    desc: "Enjoy the benefits of an Internal Network & Management for a fraction of the price of staffing one. We reduce the business impact of IT failures by minimizing their occurrence, and any unforeseen issues are dealt with as a top priority covering all aspects of your network like security, data, and hardware.",
  },
  {
    title: "Enterprise VOIP Business Phone Solutions",
    desc: "Unified business communication system with our VOIP Solution. Voice | Video Conferencing | Messaging",
  },
  {
    title: "Enterprise Infrastructure Cabling Management",
    desc: "Our Enterprise Infrastructure Cabling Management service ensures the organized and efficient setup, documentation, and maintenance of cabling systems within your business. We follow industry standards, implement structured cabling, and provide scalable solutions for a reliable and future-ready network.",
  },
  // {
  //   title: ITservices.anti_spam,
  //   desc: ITservices.anti_spam_desc,
  // },
  // {
  //   title: ITservices.backup,
  //   desc: ITservices.backup_desc,
  // },
  // {
  //   title: ITservices.replication,
  //   desc: ITservices.replication_desc,
  // },
  // {
  //   title: ITservices.compliance_reporting,
  //   desc: ITservices.compliance_reporting_desc,
  // },
];

export const IndividualStaffServices = [
  {
    title: "Contractual Talent Acquisition",
    desc: "Tackle your short-term recurring talent needs with our Contractual Talent Acquisition services",
  },
  {
    title: "Contractual–to–Perm Talent Acquisition",
    desc: "Need time to think about hiring due to uncertainty and budgetary concerns? Work with our consultants for a pre-determined term and hire them full-time when they match or exceed your needs.",
  },
  {
    title: "Permanent Talent Acquisition",
    desc: "Boost your productivity with skillful and reliable talents for crucial full-time permanent roles",
  },
];

export const IndividualITConsultingServices = [
  {
    title: "Customized Training Programs",
    desc: "Our Customized Training Programs cater to the unique needs of your organization, offering personalized learning experiences. We design and implement tailor-made training solutions to enhance employee skills, boost productivity, and align with your business objectives.",
  },
  {
    title: "Ethics and Compliance Training",
    desc: "Our Ethics and Compliance Training services are crafted to instill a culture of integrity within your organization. We provide comprehensive training programs to ensure that employees are well-versed in ethical practices and compliance standards",
  },
  {
    title: "Policy Development and Implementation",
    desc: "Our Policy Development and Implementation services focus on creating robust organizational frameworks tailored to your business needs. We specialize in crafting and implementing policies that align with industry regulations and internal objectives",
  },
  {
    title: "Leadership Development",
    desc: "We offer targeted programs to nurture effective communication, strategic thinking, and decision-making abilities among leaders. ",
  },
  {
    title: "Technology Inclusion Training",
    desc: "Our Technology Inclusion Training is designed to bridge the digital divide and create an inclusive workplace. We provide specialized programs to foster a tech-savvy and diverse workforce.",
  },
  {
    title: "Cybersecurity Training",
    desc: "Our Cybersecurity Training services empower your workforce to navigate the digital landscape securely. We offer comprehensive programs to enhance awareness, mitigate risks, and build a vigilant cybersecurity culture.",
  },
];
export const CodeList = [
  {
    code: "561320",
    title: "Temporary Help Services",
  },
  {
    code: "541511",
    title: "Custom Computer Programming Services",
  },
  {
    code: "541512",
    title: "Computer Systems Design Services",
  },
  {
    code: "541513",
    title: "Computer Facilities Management Services",
  },
  {
    code: "541519",
    title: "Other Computer Related Services",
  },
  {
    code: "541611",
    title:
      "Administrative Management and General Management Consulting Services",
  },
];
