import React from "react";
import { useDispatch, useSelector } from "react-redux";
import our_service_background_img from "../../assets/images/our_service_background_img.png";
import {
  changeMenuStatus,
  changeServiceData,
} from "../../redux/HomeReduxStore";
import {
  PartnerViewMoreButton,
  PartnerViewMoreIcon,
} from "../../style/about.style";
import { Card, CardContainer } from "../../style/common.style";
import {
  CardText,
  Content,
  LinkContainer,
  ServiceIconContainer,
  ServiceImg,
  ServicesAvtarContainer,
  ServicesCardAvtar,
  ServicesCardAvtarContainer,
  ServiceSectionContainer,
  ServiceTextContainer,
  SmallTitle,
  Title,
} from "../../style/home.style";
import { CommonColor } from "../../utility/color";
import Images from "../../utility/common.image";
import { Service, serviceList } from "../../utility/common.mockdata";
import { CommonString } from "../../utility/constant";
import { CommonIcon } from "../../utility/icon";
export default function ServiceSection(props) {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.HomeReduxStore.serviceData);
  return (
    <ServiceSectionContainer>
      <ServiceImg noMargin={props.noMargin} src={serviceData.img} />
      <ServiceTextContainer>
        <SmallTitle
          isService={true}
          className="laptop"
          color={CommonColor.white}
        >
          Our Services
        </SmallTitle>
        <Title isService={true} className="laptop" color={CommonColor.white}>
          {serviceData.menu_status}
        </Title>
        <h2 className="mobile serviceForMobile">
          <b>
            Our <span>Services</span>
          </b>
        </h2>
        <CardContainer>
          {Service.map((data) => (
            <Card
              onClick={() => {
                dispatch(changeMenuStatus(data.menu_status));
                window.scrollTo(0, 0);
              }}
              onMouseEnter={() => dispatch(changeServiceData(data))}
            >
              <ServicesCardAvtarContainer>
                <ServicesAvtarContainer>
                  <ServicesCardAvtar icon={data.icon} />
                </ServicesAvtarContainer>
                <CardText>{data.menu_status}</CardText>
              </ServicesCardAvtarContainer>
              {/* <p>{data.service_desc}</p> */}
              {/* <ServiceIconContainer /> */}
              <PartnerViewMoreButton color={CommonColor.secondary_color}>
                Learn More
                <PartnerViewMoreIcon icon={CommonIcon.partner_arrow} />
              </PartnerViewMoreButton>
            </Card>
          ))}

          <Card
            href={CommonString.enterpriseVIOBusiness_service_link}
            target={CommonString.black}
            onMouseEnter={() =>
              dispatch(
                changeServiceData({
                  menu_status: CommonString.enterpriseVIOBusiness_service_name,
                  img: Images.service_1,
                })
              )
            }
          >
            <ServicesCardAvtarContainer>
              <ServicesAvtarContainer>
                <ServicesCardAvtar icon={CommonIcon.voip} />
              </ServicesAvtarContainer>
              {/* <ServiceIconContainer /> */}
              <CardText>
                {CommonString.enterpriseVIOBusiness_service_name}
              </CardText>
            </ServicesCardAvtarContainer>
            {/* <p>{CommonString.enterpriseVIOBusiness_service_desc}</p> */}
            <PartnerViewMoreButton>
              View More
              <PartnerViewMoreIcon icon={CommonIcon.partner_arrow} />
            </PartnerViewMoreButton>
          </Card>
        </CardContainer>
      </ServiceTextContainer>
    </ServiceSectionContainer>
  );
}
