import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../component/Button";
import { changeMenuStatus } from "../../redux/HomeReduxStore";
import { Span } from "../../style/common.style";
import {
  AboutSectionContainer,
  Content,
  SmallTitle,
  Title,
} from "../../style/home.style";
import { CommonString } from "../../utility/constant";

export default function AboutSection() {
  const dispatch = useDispatch();
  return (
    <AboutSectionContainer>
      <SmallTitle>About us</SmallTitle>
      <Title>
        We are here to help you<Span> SUCCEED!</Span>
      </Title>
      {/* <TitleInSecondaryColor>SUCCEED!</TitleInSecondaryColor> */}
      <Content width={70}>
        {/* Olan Associates LLC is a global IT and Management Consulting company
        made up of IT experts, creative and strategy technocrats, engineers as
        well as project managers with strong zeal and in-depth knowledge to
        deliver quality, innovative and cost effective services and solutions.
        We Provide organizations with reliable, business-focused IT services
        that optimize operations, manage risk and deliver measurable business
        value. */}
        Olan Associates is a global Consulting and Management firm that provides organizations with reliable, business-focused services and solutions that optimize operations manage risk and deliver measurable business value. Our areas of practice include Information Technology, Human Resource Consulting, and Management Consulting. Olan Associates also provides Staff Augmentation and Other Administrative Services
      </Content>
     
    </AboutSectionContainer>
  );
}
