import React from "react";
import CodeSection from "../HomeScreen/CodeSection";
import ServiceSection from "../HomeScreen/ServiceSection";

export default function ServicePage() {
  return (
    <>
      <ServiceSection />
      <CodeSection />
    </>
  );
}
