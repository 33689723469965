import { Icon } from "@iconify/react";
import React, { useRef, useState } from "react";
import { sliderData, sliderDataForMobile } from "../../utility/common.mockdata";
import AboutSection from "./AboutSection";
import BlogSection from "./BlogSection";
import ContactUsSection from "./ContactUsSection";
import OurClientSection from "./OurClientSection";
import ServiceSection from "./ServiceSection";
import Carousel from "react-bootstrap/Carousel";
import Button from "../../component/Button";
import { CommonString } from "../../utility/constant";
import CodeSection from "./CodeSection";
export default function HomePage() {
  const ref = useRef(null);
  const [activeIndex, setactiveIndex] = useState(0);
  return (
    <>
      <Carousel
        ref={ref}
        controls={false}
        indicators={false}
        onSlide={(e) => {
          setactiveIndex(e);
        }}
        pause="hover"
        className="laptop"
        style={{ scrollSnapType: "x mandatory", scrollBehavior: "smooth" }}
      >
        {sliderData.map((e, index) => (
          <Carousel.Item>
            <div className="backgroung-drop" />
            <div className="sliderImgContainer">
              <img className="d-block imgv" src={e.image} alt="First slide" />
            </div>

            <h1 className="SliderTitle">{e.title}</h1>
            <div className="bottom-block fade-in">
              <div>
                <div className="leftButton" onClick={() => ref.current.prev()}>
                  <Icon
                    icon="ic:sharp-navigate-before"
                    className="activeicon"
                  />
                </div>
                <div className="rightButton" onClick={() => ref.current.next()}>
                  <Icon icon="ic:sharp-navigate-next" className="activeicon" />
                </div>
              </div>
              <div className="content-block">
                <div className="content-slider">{e.desc}</div>
                <div className="activeNumber">0{activeIndex + 1}</div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <Carousel
        ref={ref}
        controls={false}
        indicators={false}
        onSlide={(e) => {
          setactiveIndex(e);
        }}
        pause="hover"
        className="mobile"
        style={{ scrollSnapType: "x mandatory", scrollBehavior: "smooth" }}
      >
        {sliderDataForMobile.map((e, index) => (
          <Carousel.Item>
            <div className="slider">
              <div className="backgroung-drop" />
              <div className="sliderImgContainer">
                <img className="d-block imgv" src={e.image} alt="First slide" />
              </div>
              <div className="SliderTitle">
                <h1>{e.title}</h1>
                
              </div>
              <div className="activeNumber">0{activeIndex + 1}</div>
              <div className="bottom-block fade-in">
                <div>
                  <div
                    className="leftButton"
                    onClick={() => ref.current.prev()}
                  >
                    <Icon
                      icon="ic:sharp-navigate-before"
                      className="activeicon"
                    />
                  </div>
                  <div
                    className="rightButton"
                    onClick={() => ref.current.next()}
                  >
                    <Icon
                      icon="ic:sharp-navigate-next"
                      className="activeicon"
                    />
                  </div>
                </div>
                <div className="content-block">
                  <div className="content-slider">{e.desc}</div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <AboutSection />
      <ServiceSection noMargin={true} />
      <OurClientSection />
      <ContactUsSection />
      {/* <BlogSection /> */}
    </>
  );
}
