import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import {
  changeIsButton,
  changeMenuStatus,
} from "../../../redux/HomeReduxStore";
import { AboutUsImg } from "../../../style/about.style";
import {
  ButtonName,
  SolidButtonForMobileView,
  SolidTransperantButton,
  Span,
} from "../../../style/common.style";
import {
  ServiceContainer,
  ServiceImg,
  ServiceList,
  ServiceListContainer,
  ServiceMainContainer,
  ServiceTitleContainer,
} from "../../../style/service.style";
import { CommonString } from "../../../utility/constant";
import service_background_img from "../../../assets/images/service_background_img.png";
import {
  IndividualEnterpriseManagementServices,
  IndividualStaffServices,
} from "../../../utility/common.mockdata";
import { SmallTitle, Title } from "../../../style/home.style";
import { CommonColor } from "../../../utility/color";
import Images from "../../../utility/common.image";
import Button from "../../../component/Button";
import { onHoverButtonAnimation } from "../../../utility/function";
export default function StaffAugmentationServices() {
  const dispatch = useDispatch();
  return (
    <>
      <ServiceImg src={Images.service_5} />
      <ServiceTitleContainer>
        <Title color={CommonColor.white}>Staff Augmentation and Other Administrative Services</Title>
        <SmallTitle small={true} color={CommonColor.white}>
        Our team of experts has 25+ years of combined experience in placing resources with Government and Commercial clients. Sufficient time and resources are allocated for the comprehensive study of the client’s requirements, screening of candidates, recruitment process, and personnel management to deliver the most efficient service currently available in the market.
        </SmallTitle>
        <SolidTransperantButton
          onMouseLeave={() => dispatch(changeIsButton(false))}
          onMouseEnter={() => dispatch(changeIsButton(true))}
          id={CommonString.btn_posnawr}
          onClick={() => {
            dispatch(changeMenuStatus(CommonString.contact_us));
            window.scrollTo(0, 0);
          }}
        >
          <ButtonName>Contact Us</ButtonName>
          <span></span>
        </SolidTransperantButton>
        <SolidButtonForMobileView
          onMouseLeave={() => dispatch(changeIsButton(false))}
          onMouseEnter={() => dispatch(changeIsButton(true))}
          onMouseMove={(e) => onHoverButtonAnimation(e)}
          onClick={() => {
            dispatch(changeMenuStatus(CommonString.contact_us));
            window.scrollTo(0, 0);
          }}
        >
          Contact Us
        </SolidButtonForMobileView>
      </ServiceTitleContainer>
      <ServiceMainContainer>
        <Grid container>
          {IndividualStaffServices.map((data, index) => (
            <Grid item xs={12} md={4}>
              <ServiceContainer
                onMouseLeave={() => dispatch(changeIsButton(false))}
                onMouseEnter={() => dispatch(changeIsButton(true))}
                minHeight={
                  CommonString.service_container_height_in_network_management
                }
                isGivePadding={index % 2 === 0}
              >
                <Span bold={true}>{data.title}</Span>
                <ServiceListContainer>
                  <ServiceList>{data.desc}</ServiceList>
                </ServiceListContainer>
              </ServiceContainer>
            </Grid>
          ))}
        </Grid>
      </ServiceMainContainer>
    </>
  );
}
