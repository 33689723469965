import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../utility/color";
import { CommonString } from "../utility/constant";
import { keyframes } from "styled-components";

const AboutSectionContainer = styled.div`
  padding: ${CommonString.common_padding};
  background: ${CommonColor.secondary_background_color};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (${CommonString.mobile_screen}) {
    padding: 20px ${CommonString.common_padding_for_mobile};
  }
`;
const SmallTitle = styled.p`
  // text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  display: ${(props) => (props.noTitle ? "none" : "block")};
  width: ${(props) => (props.small ? "50%" : "100%")};
  color: ${(props) => props.color ?? CommonColor.primary_color};
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    color: ${(props) => props.color ?? CommonColor.primary_color};
    display: ${(props) => (props.isService ? "none" : "block")};
    padding: 0 10px;
  }
`;
const Title = styled.h2`
  display: ${(props) => (props.noTitle ? "none" : "block")};
  font-weight: bold;
  width: ${(props) => (props.small ? "50%" : "100%")};
  text-align: ${(props) => props.align ?? "center"};
  color: ${(props) => props.color ?? CommonColor.primary_color};
  margin-bottom: 10px;
  @media (${CommonString.mobile_screen}) {
    color: ${(props) => props.color ?? CommonColor.primary_color};
    width: 100%;
    display: ${(props) => (props.isService ? "none" : "block")};
  }
`;
const TitleInSecondaryColor = styled.h3`
  text-align: center;
  color: ${CommonColor.secondary_color};
`;
const Content = styled.p`
  color: ${CommonColor.primary_color};
  font-size: 13px;
  width: ${(props) => (props.width ? props.width + "%" : "60%")};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
  letter-spacing: 1px;
  @media (${CommonString.mobile_screen}) {
    width: 100%;
  }
`;

// service css
const fadeInAnimation = keyframes`
0% {
  opacity: 0; 
  background-color: rgb(0, 0, 0);
}
100% {
 opacity: 1;
  background-color: rgba(0, 0, 0, 0.65);
}
`;
const ServiceSectionContainer = styled.div``;
const ServiceTextContainer = styled.div`
  margin-top: -350px;
  text-align: center;
  padding: ${CommonString.common_padding};
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    margin-top: 0px;
    padding: ${CommonString.common_padding_for_mobile};
    position: relative;
    p {
      color: ${CommonColor.primary_color};
    }
    h2 {
      color: ${CommonColor.primary_color};
    }
  }
`;

const ServiceImg = styled.img`
  width: 100%;
  height: 90vh;
  background-position: center;
  animation-name: ${fadeInAnimation};
  animation-duration: 2s;
  @media (${CommonString.mobile_screen}) {
    margin-top: ${(props) => (props.noMargin ? "0px" : "63px")};
    height: auto;
  }
`;

const ServiceIconContainer = styled.div`
  border: 1px solid ${CommonColor.secondary_color};
  border-radius: 10px;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
`;
const CardText = styled.p`
  color: ${CommonColor.primary_color};
  font-weight: bold;
  font-size: 16px !important;
`;

const ServicesAvtarContainer = styled.span`
  color: ${CommonColor.secondary_color};
  padding: 10px;
  border-radius: 25%;
  width: fit-content;
  height: fit-content;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid ${CommonColor.secondary_color};
  @media ${CommonString.mobile} {
    font-size: 18px;
    // margin-top: 3px;
    margin-right: 10px;
    padding: 7px 9px;s
  }
`;
const ServicesCardAvtarContainer = styled.div`
  display: flex;
  @media (${CommonString.mobile_screen}) {
    align-items: center;
    margin-bottom: 10px;
    span {
      margin-bottom: 0px;
    }
  }
  //
`;
const ServicesCardAvtar = styled(Icon)`
  font-size: 24px;
`;
// client css
const ClientSectionContainer = styled.div`
  padding: ${(props) =>
    props.noPadding ? "0px" : CommonString.common_padding};
  overflow: hidden;
  @media (${CommonString.mobile_screen}) {
    padding: ${CommonString.common_padding_for_mobile};
  }
`;
const PartnerLogoContainer = styled.div`
  margin: 0px -10px;
  ul {
    display: flex !important;
    align-items: center;
  }
  @media ${CommonString.mobile} {
    justify-content: center;
    margin: 0px 0px;
  }
`;
const Slider = styled.div`
  margin: auto;
  // overflow: hidden;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 2;
  }
  &:after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  &:before {
    left: 0;
    top: 0;
  }
`;
//blog
const BlogContainer = styled.div`
  background: ${CommonColor.white};
  padding: 25px;
  margin: 10px;
  box-shadow: 0px 20.019px 50.0474px rgba(28, 9, 80, 0.07);
  cursor: pointer;
  min-height: auto;
  @media (${CommonString.mobile_screen}) {
    min-height: 377px;
  }
`;
const BlogFlexContainer = styled.div`
  display: flex;
  align-items: center;
  @media (${CommonString.mobile_screen}) {
    flex-direction: ${(props) => (props.flex ? "row" : "column-reverse")};
  }
`;
const BloggerImg = styled.img`
  width: 70px;
  height: 70px;
  margin-right: 20px;
`;
const BlogImg = styled.img`
  // height: -webkit-fill-available;
  // border-radius: 50%;
  // overflow: hidden;
  width: 180px;
`;
const BlogImgContainer = styled.div`
  // height: 170px;
  // background: red;
  // overflow: hidden;
  // border-radius: 50%;

  @media (${CommonString.mobile_screen}) {
    border-radius: 10%;
    margin-bottom: 20px;
  }
`;
const BloggerName = styled.p`
  margin-block-start: 0px;
  margin-block-end: 0px;
`;
const BlogSubTitle = styled.p`
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (${CommonString.mobile_screen}) {
    margin-right: 0px;
    font-size: 18px;
  }
`;
const SectionContent = styled.h4`
  color: ${CommonColor.primary_color};
  // margin-right:100px;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;
//contact
const CardForContact = styled.div`
  margin: 20px 10px;
  margin-bottom: 40px;
  padding: 20px;
  // min-height: 180px;
  transition-timing-function: ease-in-out;
  transition-duration: 400ms;
  border: 1px solid ${CommonColor.secondary_color};
  border-radius: 15px;
  flex: 1;
  &:hover div:first-child svg {
    background: ${CommonColor.secondary_color};
    color: ${CommonColor.white};
  }
  &:hover {
    background: ${CommonColor.secondary_background_color};
  }

  @media (${CommonString.mobile_screen}) {
    margin: 10px 0;
    margin-right: 0;
    border-radius: 10px;
    min-height: auto;
    &:hover div:first-child svg {
      background: ${CommonColor.transparent};
      color: ${CommonColor.secondary_color};
    }
    &:hover {
      background: ${CommonColor.transparent};
    }
  }
`;
const ContactCardContainer = styled.div`
  display: flex;
  @media (${CommonString.mobile_screen}) {
    display: block;
  }
`;
const CardAndContentContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;
const CardIcon = styled(Icon)`
  border: 1px solid ${CommonColor.secondary_color};
  color: ${CommonColor.secondary_color};
  padding: 14px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
`;
const CardContent = styled.p`
  margin-block-start: 7px;
  margin-block-end: 0px;
  margin-left: 0px;
  font-size: 12px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  span {
    margin-left: 20px;
    margin-bottom: -5px;
  }
  &:hover svg {
    color: ${CommonColor.primary_color};
  }
`;
const CardTitle = styled.h3`
  margin-block-start: 10px;
  margin-block-end: 10px;
  margin-left: 20px;
  font-weight: 600;
`;
const ContactUsIcon = styled(Icon)`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  color: ${CommonColor.primary_color};
  transition: all 0.2s ease-in-out;
`;
const LinkContainer = styled.a`
  color: ${(props) => props.color ?? CommonColor.primary_color};
  text-decoration: none;
  &:hover {
    color: ${CommonColor.primary_color};
  }
`;
const PartnerLogo = styled.img`
  width: 250px;
  height: 100%;
`;

//codes
const CodeCardContainer = styled.div`
  border: 1px solid ${CommonColor.light_grey};
  padding: 15px;
  border-radius: 15px;
  margin: 10px;
  min-height: 160px;
  flex: 1;
  &:hover {
    background: ${CommonColor.secondary_background_color};
  }
  @media (${CommonString.mobile_screen}) {
    min-height: 0px;
  }
`;
const CodeCardTitle = styled.div`
  color: ${CommonColor.primary_color};
  margin-bottom: 10px;
  font-weight: bold;
`;
const CodeCardContent = styled.div`
  color: ${CommonColor.secondary_color};
`;
const CardMainContainer = styled.div`
  margin: -10px;
  margin-top: 20px;
  display: flex;
  @media (${CommonString.mobile_screen}) {
    display: block;
  }
`;
export {
  ContactCardContainer,
  ServicesAvtarContainer,
  CardMainContainer,
  CodeCardTitle,
  CodeCardContent,
  CodeCardContainer,
  BlogImgContainer,
  ServicesCardAvtarContainer,
  ServicesCardAvtar,
  PartnerLogo,
  PartnerLogoContainer,
  Slider,
  CardAndContentContainer,
  CardIcon,
  CardContent,
  CardTitle,
  ContactUsIcon,
  LinkContainer,
  CardForContact,
  BlogFlexContainer,
  BloggerImg,
  BlogImg,
  BloggerName,
  BlogSubTitle,
  SectionContent,
  BlogContainer,
  ClientSectionContainer,
  CardText,
  ServiceIconContainer,
  ServiceTextContainer,
  ServiceImg,
  AboutSectionContainer,
  SmallTitle,
  Title,
  TitleInSecondaryColor,
  Content,
  ServiceSectionContainer,
};
