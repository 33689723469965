import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../utility/color";
import { CommonString } from "../utility/constant";

//button
const SolidButton = styled.div`
  background: ${CommonColor.home_bg_color};
  color: ${CommonColor.secondary_color};
  width: fit-content;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  border: 1px solid ${CommonColor.secondary_color};
  padding: 10px 25px;
  // font-weight: bold;
  z-index: 1;
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 70%;
    background-color: ${CommonColor.secondary_color};
    -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  &:hover {
    color: ${CommonColor.white};
  }
  &:hover span {
    width: 225%;
    height: 562.5px;
  }
  @media (${CommonString.mobile_screen}) {
    display: none;
  }
`;
const SolidTransperantButton = styled.div`
  cursor: pointer;
  background: ${CommonColor.home_bg_color};
  color: ${CommonColor.white};
  width: fit-content;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  border: 1px solid ${CommonColor.white};
  padding: 10px 25px;
  // font-weight: bold;
  z-index: 1;
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 70%;
    background-color: ${CommonColor.secondary_color};
    -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  &:hover {
    color: ${CommonColor.white};
    border: 1px solid ${CommonColor.secondary_color};
  }
  &:hover span {
    width: 225%;
    height: 562.5px;
  }
  @media (${CommonString.mobile_screen}) {
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: inline-flex;
  transition: 5s;
  background: ${CommonColor.white};
  border-radius: 50px;
  margin: 20px 0;
  cursor: pointer;
  width: fit-content;
  @media (${CommonString.mobile_screen}) {
    margin: 10px 0;
  }
`;

const ButtonName = styled.div`
  z-index: 2;
  position: relative;
`;

const SolidButtonForMobileView = styled.button`
  display: none;
  @media (${CommonString.mobile_screen}) {
    display: block;
    background: ${CommonColor.secondary_color};
    padding: 0.5em 2em;
    border: none;
    color: ${CommonColor.white};
    font-size: 1em;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 100px;
    margin: 0px 0;
  }
`;
const SolidButtonForNavbar = styled.button`
  background: ${CommonColor.secondary_color};
  padding: 0.5em 2em;
  border: none;
  color: ${CommonColor.white} !important;
  font-size: 1em;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 15px;
  margin: 0px 0;
  @media (${CommonString.mobile_screen}) {
    display: none;
  }
`;
//
const CardContainer = styled.div`
  display: flex;
  margin: -10px;
  margin-top: 100px;
  @media (${CommonString.mobile_screen}) {
    padding: 10px;
    display: block;
    margin-top: 20px;
  }
`;
const Card = styled.a`
  padding: 20px;
  border-radius: 15px;
  background: ${CommonColor.white};
  position: relative;
  width: 20%;
  margin: 10px;
  text-align: left;
  box-shadow: 0px 20.019px 50.0474px rgba(28, 9, 80, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  color: ${CommonColor.primary_color};
  &:hover {
    color: ${CommonColor.primary_color};
  }
  &:hover div:first-child span {
    background: ${CommonColor.secondary_color};
  }
  &:hover div:first-child svg {
    color: ${CommonColor.white};
  }
  &:hover :last-child svg {
    color: ${CommonColor.primary_color};
    transition: 0.6s;
    rotate: -45deg;
  }
  p:last-child {
    color: ${CommonColor.secondary_color};
  }
  p {
    margin-bottom: 0;
  }
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    margin: 15px 0;
  }
`;
const Div = styled.div``;
const MenuIconForMobile = styled(Icon)`
  display: none;
  color: ${CommonColor.secondary_color};
  @media (${CommonString.mobile_screen}) {
    display: block;
  }
`;
const Span = styled.span`
  color: ${CommonColor.secondary_color};
  font-weight: ${(props) => (props.bold ? "bold" : "inherit")};
`;
const HeaderContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.328); */
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background: ${CommonColor.primary_color};
  backdrop-filter: blur(18px);
  box-shadow: 0px 10px 12px rgba(10, 10, 10, 0.336);
  padding: 10px ${CommonString.common_padding};
  @media (${CommonString.mobile_screen}) {
    padding: 10px ${CommonString.common_padding_for_mobile};
  }
`;
const TitleContainer = styled.div`
  display: flex;
  position: absolute;
  top: 40vh;
  color: white;
  z-index: 9;
  align-items: center;
  justify-content: center;
  // width: 100vw;
  flex-direction: column;
  // width:100;
  &:p {
    width: 70% !important;
    // margin: 0px;
  }
  @media (${CommonString.mobile_screen}) {
    top: 9vh;
    // padding: 10px ${CommonString.common_padding_for_mobile};
  }
`;
const Body = styled.div`
  height: ${(props) => (props.isOpenMenu ? "100vh" : "auto")};
  overflow: ${(props) => (props.isOpenMenu ? "hidden" : "auto")};
`;
const FormSolidButton = styled.input`
  background: ${CommonColor.home_bg_color}!important;
  color: ${CommonColor.secondary_color};
  width: fit-content;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  border: 1px solid ${CommonColor.secondary_color};
  padding: 10px 25px;
  // font-weight: bold;
  z-index: 1;
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 70%;
    background-color: ${CommonColor.secondary_color}!important;
    -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  &:hover {
    color: ${CommonColor.white};
  }
  &:hover span {
    width: 225%;
    height: 562.5px;
  }
  @media (${CommonString.mobile_screen}) {
    display: none;
  }
`;
export {
  FormSolidButton,
  Body,
  SolidTransperantButton,
  TitleContainer,
  SolidButton,
  ButtonContainer,
  ButtonName,
  SolidButtonForMobileView,
  CardContainer,
  Card,
  Div,
  Span,
  MenuIconForMobile,
  HeaderContainer,
  SolidButtonForNavbar,
};
