import { Grid } from "@material-ui/core";
import React from "react";
import {
  BlogImageContainer,
  BlogMainContainer,
  BlogMainImg,
  BlogSectionNew,
  BlogSubImageContainer,
  BlogSubImg,
  BlogSubTitle,
  BlogText,
  BlogTextContainer,
  BlogTextMargin,
} from "../../style/blog.style";
import { Span, TitleContainer } from "../../style/common.style";
import { BlogFlexContainer, Title } from "../../style/home.style";
import { CommonColor } from "../../utility/color";
import Images from "../../utility/common.image";
import BlogSection from "../HomeScreen/BlogSection";

export default function Blog1() {
  return (
    <BlogMainContainer>
      <BlogFlexContainer>
        {/* <BlogSectionNew> */}
        <Grid container>
          <Grid item md={6} xs={12}>
            <BlogMainImg src={Images.blog_background_img_1} />
          </Grid>
          <Grid item md={6} xs={12}>
            <BlogSectionNew>
              <BlogSubTitle>
                A presentation at the EXIM Bank Washington DC by Dr. Elekwachi,
                President, Olan Associates LLC.
              </BlogSubTitle>
              <BlogText>
                The president of Olan Associates, Dr Elekwachi, made a
                presentation at the just concluded EXIM bank meeting with
                business owners in Washington DC.
              </BlogText>
            </BlogSectionNew>
          </Grid>

          {/* <Grid item md={1} xs={12}></Grid> */}
        </Grid>
        {/* </BlogSectionNew> */}
      </BlogFlexContainer>
      <BlogSubImageContainer>
        <Grid container>
          <Grid item md={4} xs={12}>
            <BlogImageContainer>
              <BlogSubImg src={Images.blog_5} />
            </BlogImageContainer>
          </Grid>
          <Grid item md={4} xs={12}>
            <BlogImageContainer>
              <BlogSubImg src={Images.blog_6} />
            </BlogImageContainer>
          </Grid>
          <Grid item md={4} xs={12}>
            <BlogImageContainer>
              <BlogSubImg src={Images.blog_7} />
            </BlogImageContainer>
          </Grid>
        </Grid>
      </BlogSubImageContainer>
      <BlogSection />
    </BlogMainContainer>
  );
}
