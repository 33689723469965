export const CommonColor = {
  white: "white",
  secondary_color: "#DC691D",
  primary_color: "rgb(8, 21, 26)",
  secondary_background_color: "#DC691D20",
  transparent: "transparent",
  small_text_color: "#ffffff95",
  black: "black",
  light_grey: "lightgrey",
  input_color: "#f4f6fc",
  grey: "#afadad",
  border:"#d3d3d355"
};
