import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import {
  changeIsButton,
  changeMenuStatus,
} from "../../../redux/HomeReduxStore";
import { AboutUsImg } from "../../../style/about.style";
import {
  ButtonName,
  SolidButtonForMobileView,
  SolidTransperantButton,
  Span,
} from "../../../style/common.style";
import {
  ServiceContainer,
  ServiceImg,
  ServiceList,
  ServiceListContainer,
  ServiceMainContainer,
  ServiceTitleContainer,
} from "../../../style/service.style";
import { CommonString } from "../../../utility/constant";
import service_background_img from "../../../assets/images/service_background_img.png";
import {
  IndividualEnterpriseManagementServices,
  IndividualITservices,
} from "../../../utility/common.mockdata";
import { SmallTitle, Title } from "../../../style/home.style";
import { CommonColor } from "../../../utility/color";
import Images from "../../../utility/common.image";
import Button from "../../../component/Button";
import { onHoverButtonAnimation } from "../../../utility/function";
export default function ManageITService() {
  const dispatch = useDispatch();
  return (
    <>
      <ServiceImg src={Images.service_2} />
      <ServiceTitleContainer>
        <Title color={CommonColor.white}>Information Technology Consulting Services</Title>
        <SmallTitle small={true} color={CommonColor.white}>
        Our Information Technology Consulting Services offer comprehensive guidance for businesses, covering strategic IT planning, system implementation, and ongoing support. We specialize in optimizing IT infrastructure, enhancing cybersecurity, and providing tailored solutions to meet the evolving technology needs of your organization.
        </SmallTitle>
        <SolidTransperantButton
          onMouseLeave={() => dispatch(changeIsButton(false))}
          onMouseEnter={() => dispatch(changeIsButton(true))}
          id={CommonString.btn_posnawr}
          onClick={() => {
            dispatch(changeMenuStatus(CommonString.contact_us));
            window.scrollTo(0, 0);
          }}
        >
          <ButtonName>Contact Us</ButtonName>
          <span></span>
        </SolidTransperantButton>
        <SolidButtonForMobileView
          onMouseLeave={() => dispatch(changeIsButton(false))}
          onMouseEnter={() => dispatch(changeIsButton(true))}
          onMouseMove={(e) => onHoverButtonAnimation(e)}
          onClick={() => {
            dispatch(changeMenuStatus(CommonString.contact_us));
            window.scrollTo(0, 0);
          }}
        >
          Contact Us
        </SolidButtonForMobileView>
      </ServiceTitleContainer>
      <ServiceMainContainer>
        <Grid container>
          {IndividualITservices.map((data, index) => (
            <Grid item xs={12} md={4}>
              <ServiceContainer
                onMouseLeave={() => dispatch(changeIsButton(false))}
                onMouseEnter={() => dispatch(changeIsButton(true))}
                minHeight={
                  CommonString.service_container_height_in_network_management
                }
                isGivePadding={index % 2 === 0}
              >
                <Span bold={true}>{data.title}</Span>
                <ServiceListContainer>
                  <ServiceList>{data.desc}</ServiceList>
                </ServiceListContainer>
              </ServiceContainer>
            </Grid>
          ))}
        </Grid>
      </ServiceMainContainer>
    </>
  );
}
