import { Grid } from "@material-ui/core";
import React from "react";
import {
  BlogMainContainer,
  BlogMainImg,
  BlogSectionNew,
  BlogSubTitle,
  BlogText,
  BlogTextContainer,
  BlogTextMargin,
} from "../../style/blog.style";
import { Span, TitleContainer } from "../../style/common.style";
import { LinkContainer, Title } from "../../style/home.style";
import { CommonColor } from "../../utility/color";
import Images from "../../utility/common.image";
import { CommonString } from "../../utility/constant";
import BlogSection from "../HomeScreen/BlogSection";

export default function Blog4() {
  return (
    <BlogMainContainer>
      <Grid container>
        <Grid item md={4} xs={12}>
          <BlogMainImg src={Images.blog_4} />
        </Grid>
        <Grid item md={8} xs={12}>
          <BlogSectionNew>
            <BlogSubTitle>
              About UsA presentation at the EXIM Bank Washington DC by Dr.
              Elekwachi, President, Olan Associates LLC.
            </BlogSubTitle>
            <BlogText>Click link below for more information.</BlogText>
            <BlogText>
              <LinkContainer
                href="http://files.constantcontact.com/603ef079001/0de2da0d-b869-4ca4-adbe-50a1d3c3de08.pdf"
                target={CommonString.black}
              >
                <Span>
                  CRMSDC’S LEADERS AND LEGENDS FEATURING THE TOP 100 MBE AWARDS©
                </Span>
              </LinkContainer>
            </BlogText>
          </BlogSectionNew>
        </Grid>
      </Grid>
      <BlogSection />
    </BlogMainContainer>
  );
}
