import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import {
  changeIsButton,
  changeMenuStatus,
} from "../../../redux/HomeReduxStore";
import {
  ButtonName,
  SolidButtonForMobileView,
  SolidTransperantButton,
  Span,
} from "../../../style/common.style";
import {
  ServiceContainer,
  ServiceImg,
  ServiceList,
  ServiceListContainer,
  ServiceMainContainer,
  ServiceTitleContainer,
} from "../../../style/service.style";
import { CommonString } from "../../../utility/constant";
import service_background_img from "../../../assets/images/service_background_img.png";
import { IndividualEnterpriseManagementServices } from "../../../utility/common.mockdata";
import { SmallTitle, Title } from "../../../style/home.style";
import { CommonColor } from "../../../utility/color";
import Images from "../../../utility/common.image";
import Button from "../../../component/Button";
import { onHoverButtonAnimation } from "../../../utility/function";
export default function EnterpriseNetworkManagement() {
  const dispatch = useDispatch();
  return (
    <>
      <ServiceImg src={Images.service_4} />
      <ServiceTitleContainer>
        <Title color={CommonColor.white}>Management Consulting</Title>
        <SmallTitle small={true} color={CommonColor.white}>
        Our Management Consulting services provide strategic guidance to optimize your business processes and achieve organizational goals. We specialize in analyzing current practices, identifying areas for improvement, and implementing effective solutions.
        </SmallTitle>
        <SolidTransperantButton
          onMouseLeave={() => dispatch(changeIsButton(false))}
          onMouseEnter={() => dispatch(changeIsButton(true))}
          id={CommonString.btn_posnawr}
          onClick={() => {
            dispatch(changeMenuStatus(CommonString.contact_us));
            window.scrollTo(0, 0);
          }}
        >
          <ButtonName>Contact Us</ButtonName>
          <span></span>
        </SolidTransperantButton>
        <SolidButtonForMobileView
          onMouseLeave={() => dispatch(changeIsButton(false))}
          onMouseEnter={() => dispatch(changeIsButton(true))}
          onMouseMove={(e) => onHoverButtonAnimation(e)}
          onClick={() => {
            dispatch(changeMenuStatus(CommonString.contact_us));
            window.scrollTo(0, 0);
          }}
        >
          Contact Us
        </SolidButtonForMobileView>
        {/* <Button
          name={"Contact Us"}
             onClick={() => {
            dispatch(changeMenuStatus(CommonString.contact_us));
            window.scrollTo(0, 0);
          }}
        /> */}
      </ServiceTitleContainer>
      <ServiceMainContainer>
        <Grid container>
          {IndividualEnterpriseManagementServices.map((data, index) => (
            <Grid item xs={12} md={4}>
              <ServiceContainer
                onMouseLeave={() => dispatch(changeIsButton(false))}
                onMouseEnter={() => dispatch(changeIsButton(true))}
                minHeight={
                  CommonString.service_container_height_in_network_management
                }
                isGivePadding={index % 2 === 0}
              >
                <Span bold={true}>{data.title}</Span>
                <ServiceListContainer>
                  {data.desc}
                </ServiceListContainer>
              </ServiceContainer>
            </Grid>
          ))}
        </Grid>
      </ServiceMainContainer>
    </>
  );
}
