import React from "react";
import {
  ClientSectionContainer,
  PartnerLogo,
  PartnerLogoContainer,
  Slider,
  SmallTitle,
  Title,
} from "../../style/home.style";
import { Div } from "../../style/common.style";
import { imageList } from "../../utility/common.mockdata";
import { CommonString } from "../../utility/constant";

export default function OurClientSection() {
  return (
    <ClientSectionContainer>
      <SmallTitle>Our Clients</SmallTitle>
      <Title></Title>
      <PartnerLogoContainer>
        <Slider>
          <Div className={CommonString.slide_track}>
            {imageList.map((data, index) => (
              <PartnerLogo key={index} src={data} />
            ))}
          </Div>
        </Slider>
      </PartnerLogoContainer>
    </ClientSectionContainer>
  );
}
