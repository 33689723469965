import img_11 from "../assets/11.jpg";
import img_22 from "../assets/22.jpg";
import img_33 from "../assets/33.jpg";
import horizontal_logo from "../assets/logo_horizontal.png";
import sliderImg_1 from "../assets/images/slider/sliderImg_1.png";
import sliderImg_2 from "../assets/images/slider/sliderImg_2.png";
import sliderImg_3 from "../assets/images/slider/sliderImg_3.png";
import sliderImg_4 from "../assets/images/slider/sliderImg_4.png";
import sliderImg_5 from "../assets/images/slider/sliderImg_5.png";
import blog_img_1 from "../assets/images/Blog/blog_img_1.png";
import blog_img_2 from "../assets/images/Blog/blog_img_2.png";
import blog_img_3 from "../assets/images/Blog/blog_img_3.png";
import blog_img_4 from "../assets/images/Blog/blog_img_4.png";
import blog_background_img_1 from "../assets/images/Blog/1.png";
import blog_5 from "../assets/images/Blog/2.png";
import blog_6 from "../assets/images/Blog/3.png";
import blog_7 from "../assets/images/Blog/4.png";
import service_1 from "../assets/images/service_1.png";
import service_2 from "../assets/images/service_4.png";
import service_4 from "../assets/images/service_4.jfif";
import service_3 from "../assets/images/service_3.jfif";
import service_5 from "../assets/images/service_5.jfif";
import aboutUsBackgroundImg from "../assets/images/service_background_img.png";
import sliderImgForMobile_1 from "../assets/images/slider/sliderImgForMobile_1.png";
import sliderImgForMobile_2 from "../assets/images/slider/sliderImgForMobile_2.png";
import sliderImgForMobile_3 from "../assets/images/slider/sliderImgForMobile_3.png";
import sliderImgForMobile_4 from "../assets/images/slider/sliderImgForMobile_4.png";
import sliderImgForMobile_5 from "../assets/images/slider/sliderImgForMobile_5.png";


const Images = {
  aboutUsBackgroundImg:aboutUsBackgroundImg,
  img_11: img_11,
  img_22: img_22,
  img_33: img_33,
  horizontal_logo: horizontal_logo,
  sliderImg_1: sliderImg_1,
  sliderImg_2: sliderImg_2,
  sliderImg_3: sliderImg_3,
  sliderImg_4: sliderImg_4,
  sliderImg_5: sliderImg_5,
  blog_1: blog_img_1,
  blog_2: blog_img_2,
  blog_3: blog_img_3,
  blog_4: blog_img_4,
  blog_background_img_1: blog_background_img_1,
  blog_5: blog_5,
  blog_6: blog_6,
  blog_7: blog_7,
  service_1: service_1,
  service_2: service_2,
  service_4: service_4,
  service_3: service_3,
  service_5: service_5,
  sliderImgForMobile_1: sliderImgForMobile_1,
  sliderImgForMobile_2: sliderImgForMobile_2,
  sliderImgForMobile_3: sliderImgForMobile_3,
  sliderImgForMobile_4: sliderImgForMobile_4,
  sliderImgForMobile_5: sliderImgForMobile_5,
};

export default Images;
