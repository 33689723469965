import { CommonString } from "./constant";
import HomePage from "../screen/HomeScreen/HomePage";
import AboutPage from "../screen/AboutScreen/AboutPage";
import ContactUsPage from "../screen/ContactUsScreen/ContactUsPage";
import ServicePage from "../screen/ServiceScreen/ServicePage";
import EnterpriseNetworkManagement from "../screen/ServiceScreen/Services/EnterpriseNetworkManagement";
import StaffAugmentationServices from "../screen/ServiceScreen/Services/StaffAugmentationServices";
import ManageITService from "../screen/ServiceScreen/Services/ManageITService";
import ITConsultingServices from "../screen/ServiceScreen/Services/ITConsultingServices";
import Blog1 from "../screen/BlogScreen/Blog1";
import Blog4 from "../screen/BlogScreen/Blog4";
import Blog3 from "../screen/BlogScreen/Blog3";
import Blog2 from "../screen/BlogScreen/Blog2";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";

export function isActive(e) {
  if (
    e === "Information Technology Consulting Services" ||
    e === "Human Resource Consulting" ||
    e === "Management Consulting" ||
    e === "Staff Augmentation and Other Administrative Services"
  ) {
    return CommonString.our_work;
  } else if (
    e === CommonString.blog_1 ||
    e === CommonString.blog_2 ||
    e === CommonString.blog_3 ||
    e === CommonString.blog_4
  ) {
    return CommonString.our_blog;
  }
  return e;
}
export function isActiveForFooter(e) {
  if (
    e === CommonString.blog_1 ||
    e === CommonString.blog_2 ||
    e === CommonString.blog_3 ||
    e === CommonString.blog_4
  ) {
    return CommonString.our_blog;
  }
  return e;
}

export const GetView = (status, onClick) => {
  if (status === CommonString.home) {
    localStorage.setItem(CommonString.view_status, CommonString.home);
    return <HomePage status={CommonString.home} onClick={(e) => onClick(e)} />;
  }
  if (status === CommonString.about_us) {
    localStorage.setItem(CommonString.view_status, CommonString.about_us);
    return <AboutPage />;
  }
  if (status === CommonString.our_work) {
    localStorage.setItem(CommonString.view_status, CommonString.our_work);
    return <ServicePage />;
  }
  if (status === CommonString.our_blog) {
    localStorage.setItem(CommonString.view_status, CommonString.our_blog);
    return <Blog1 />;
  }
  if (status === CommonString.contact_us) {
    localStorage.setItem(CommonString.view_status, CommonString.contact_us);
    return <ContactUsPage />;
  }
  if (status === "Information Technology Consulting Services") {
    localStorage.setItem(
      CommonString.view_status,
      "Information Technology Consulting Services"
    );
    return <ManageITService />;
  }
  if (status === "Human Resource Consulting") {
    localStorage.setItem(
      CommonString.view_status,
      "Human Resource Consulting"
    );
    return <ITConsultingServices />;
  }
  if (status === "Management Consulting") {
    localStorage.setItem(
      CommonString.view_status,
      "Management Consulting"
    );
    return <EnterpriseNetworkManagement />;
  }
  if (status === "Staff Augmentation and Other Administrative Services") {
    localStorage.setItem(
      CommonString.view_status,
      "Staff Augmentation and Other Administrative Services"
    );
    return <StaffAugmentationServices />;
  }
  if (status === CommonString.blog_1) {
    localStorage.setItem(CommonString.view_status, CommonString.blog_1);
    return <Blog1 />;
  }
  if (status === CommonString.blog_2) {
    localStorage.setItem(CommonString.view_status, CommonString.blog_2);
    return <Blog2 />;
  }
  if (status === CommonString.blog_3) {
    localStorage.setItem(CommonString.view_status, CommonString.blog_3);
    return <Blog3 />;
  }
  if (status === CommonString.blog_4) {
    localStorage.setItem(CommonString.view_status, CommonString.blog_4);
    return <Blog4 />;
  }
};
export function onSubmitContactForm(data) {
  // data.preventDefault();
  emailjs
    .sendForm(
      "service_a9sfu3p",
      "template_aaj1qnq",
      data.current,
      "q5vs-OeoirHQwARU3"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}
export function onHoverButtonAnimation() {
  (function () {
    const buttons = document.querySelectorAll("#btn-posnawr");
    buttons.forEach((button) => {
      ["mouseenter", "mouseout"].forEach((evt) => {
        button.addEventListener(evt, (e) => {
          let parentOffset = button.getBoundingClientRect(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;
          const span = button.getElementsByTagName("span");
          span[0].style.top = relY + "px";
          span[0].style.left = relX + "px";
        });
      });
    });
  })();
}
