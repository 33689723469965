import { Grid } from "@material-ui/core";
import React from "react";
import {
  CoreValueCardContainer,
  CoreValueCardContent,
  CoreValueCardTitle,
  CoreValueContainer,
  ValueCardContainer,
} from "../../style/about.style";
import { Span } from "../../style/common.style";
import { SmallTitle, Title } from "../../style/home.style";
import { CommonColor } from "../../utility/color";
import { why_choose_us } from "../../utility/common.mockdata";

export default function CoreValueSection() {
  return (
    <CoreValueContainer>
      <SmallTitle>Why Olan associates</SmallTitle>
      <Title>
        Our <Span>Core Values</Span>
      </Title>
      <br />
      <Grid container>
        {why_choose_us.map((data, index) => (
          <Grid item md={6} xs={12}>
            <CoreValueCardContainer margin={index % 2}>
              <CoreValueCardTitle>{data.title}</CoreValueCardTitle>
              <CoreValueCardContent>{data.desc}</CoreValueCardContent>
            </CoreValueCardContainer>
          </Grid>
        ))}
      </Grid>
    </CoreValueContainer>
  );
}
