import React from "react";
import {
  AboutUsContainer,
  AboutUsImg,
  AboutUsImgContainer,
} from "../../style/about.style";
import CoreValueSection from "./CoreValueSection";
import PartnerSection from "./PartnerSection";
import service_background_img from "../../assets/images/service_background_img.png";
import aboutUsForMobile from "../../assets/images/aboutUsForMobile.png";
import {
  ButtonName,
  Div,
  SolidButtonForMobileView,
  SolidTransperantButton,
  TitleContainer,
} from "../../style/common.style";
import { SmallTitle, Title } from "../../style/home.style";
import { CommonColor } from "../../utility/color";
import Button from "../../component/Button";
import { CommonString } from "../../utility/constant";
import { changeIsButton, changeMenuStatus } from "../../redux/HomeReduxStore";
import { useDispatch } from "react-redux";
import { onHoverButtonAnimation } from "../../utility/function";
export default function AboutPage() {
  const dispatch = useDispatch();
  return (
    <>
      <AboutUsImgContainer>
        {/* <Div> */}
        <Title small={true} color={CommonColor.white}>
          About Us
        </Title>
        <SmallTitle small={true} color={CommonColor.small_text_color}>
          {/* Olan Associates is a one-stop global IT management and consulting firm
          that provides organizations with reliable, business-focused IT
          services and solutions that optimize operations, manage risk and
          deliver measurable business value. */}
          Olan Associates is a global Consulting and Management firm that provides organizations with reliable, business-focused services and solutions that optimize operations manage risk and deliver measurable business value. Our areas of practice include Information Technology, Human Resource Consulting, and Management Consulting. Olan Associates also provides Staff Augmentation and Other Administrative Services
        </SmallTitle>

        {/* </Div> */}
      </AboutUsImgContainer>
      <CoreValueSection />
      <AboutUsContainer>
        <PartnerSection />
      </AboutUsContainer>
      {/* <ContactUsSection /> */}
    </>
  );
}
