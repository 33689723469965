import React from "react";
import { Grid } from "@material-ui/core";
import {
  BlogContainer,
  BlogFlexContainer,
  BloggerImg,
  BloggerName,
  BlogImg,
  BlogImgContainer,
  BlogSubTitle,
  ClientSectionContainer,
  SectionContent,
  SmallTitle,
  Title,
} from "../../style/home.style";
import { Div, Span } from "../../style/common.style";
import { BlogList } from "../../utility/common.mockdata";
import CustomCarousel from "../../component/Carousel";
import blog_img_1 from "../../assets/images/blog_img_1.png";
import blogger_img_1 from "../../assets/images/blogger_img_1.png";
import { changeMenuStatus } from "../../redux/HomeReduxStore";
import { useDispatch } from "react-redux";
export default function BlogSection(props) {
  const dispatch = useDispatch();
  return (
    <ClientSectionContainer noPadding={true}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <SmallTitle noTitle={props.noTitle}>News & Resources</SmallTitle>
          <Title noTitle={props.noTitle}>
            Our Latest <Span>Blogs Will Keep </Span> Everyone Updated
          </Title>
          <CustomCarousel
            data={BlogList.map((data, index) => (
              <BlogContainer
                key={index}
                onClick={() => {
                  dispatch(changeMenuStatus(data.pageName));
                  window.scrollTo(0, 0);
                }}
              >
                <BlogFlexContainer>
                  <Div>
                    <Span>{data.title}</Span>
                    <BlogSubTitle>{data.sub_title}</BlogSubTitle>
                  </Div>
                  <BlogImgContainer>
                    <BlogImg src={data.img} />
                  </BlogImgContainer>
                </BlogFlexContainer>
              </BlogContainer>
            ))}
          />
        </Grid>
      </Grid>
    </ClientSectionContainer>
  );
}
