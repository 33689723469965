import { Grid } from "@material-ui/core";
import React from "react";
import {
  BlogMainContainer,
  BlogMainImg,
  BlogSectionNew,
  BlogSubTitle,
  BlogText,
  BlogTextContainer,
  BlogTextMargin,
} from "../../style/blog.style";
import { Span, TitleContainer } from "../../style/common.style";
import { Title } from "../../style/home.style";
import { CommonColor } from "../../utility/color";
import Images from "../../utility/common.image";
import BlogSection from "../HomeScreen/BlogSection";

export default function Blog3() {
  return (
    <BlogMainContainer>
      <Grid container>
        <Grid item md={4} xs={12}>
          <BlogMainImg src={Images.blog_3} />
        </Grid>
        <Grid item md={8} xs={12}>
          <BlogSectionNew>
            <BlogSubTitle>
              Olan Associates emerges 2018 CRMSDC Supplier of the year award
              winner
            </BlogSubTitle>
            <BlogText>
              When it comes to completing large software projects, one of the
              most important factors in achieving success is to lay the
              requirements out clearly. Unfortunately, this is a lot easier said
              than done. This article will outline a few of the ways that
              requirements affect development and what happens when they aren’t
              clear from the onset.
            </BlogText>
            <BlogSubTitle>Preparation (presale/predevelopment):</BlogSubTitle>
            <BlogText>
              In the early foundation stages of a project, it is the objective
              of both the client and the team to try to come up with a set of
              technical requirements to define what a piece of software should
              do and it how it should work. This step is integral when it comes
              to accurate estimates and saving money during the development
              process. Since there is a constant flow of communication between
              the client and the developers, clarifications are fairly
              straightforward and edge cases can be covered without issue. If
              these are missed until later, it can disrupt both parties adding
              additional costs and throwing off previous estimates.
            </BlogText>
          </BlogSectionNew>
        </Grid>
      </Grid>
      <BlogSubTitle>Feature Development</BlogSubTitle>
      <BlogText>
        This phase is the most dependent on strong, detailed requirements.
        Developers will use it to frame every decision they make when it comes
        to constructing code. On top of that, it is a basis for communication
        between non-technical staff that allows a common context for how things
        should be done. Detailed requirements mean fewer decisions for
        developers to make (that could end up wrong in the client’s eyes) and
        less time used on meetings and bothering clients for more details.
      </BlogText>
      <BlogSubTitle>Quality Assurance</BlogSubTitle>
      <BlogText>
        The entire basis for performing accurate and complete Quality Assurance
        testing is the initial requirements. The more in depth those
        requirements, the easier it is for the testers to check off their
        acceptance criteria, without having to clarify with a developer or
        project manager.
      </BlogText>
      <BlogSubTitle>Maintenance</BlogSubTitle>
      <BlogText>
        In most cases it is not possible for a project to reach maintenance
        without clear requirements as otherwise it would not be possible to say
        when it has been completed! However, once in this phase, if issues arise
        or if new functionality is requested, the original requirements can be
        used as source material to determine how best to implement new features
        as well as understand what the feature will be implemented with. In the
        case that no formal documentation is written alongside the project, the
        requirements can act in place of it.
      </BlogText>
      <BlogText>
        Ultimately, the more time spent on determining clear, detailed
        requirements at the start of a project, the more time and money is saved
        in every subsequent phase.
      </BlogText>
      <BlogSection />
    </BlogMainContainer>
  );
}
