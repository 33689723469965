const CommonString = {
  service_container_height_in_it_consulting: "120px",
  about_us_container_height: "100px",
  service_container_height_in_manage_it: "150px",
  service_container_height_in_staff_augmentation: "210px",
  service_container_height_in_network_management: "210px",
  mobile_screen: "max-width: 768px",
  common_padding: "50px",
  common_top_padding: "80px",
  btn_posnawr: "btn-posnawr",
  common_padding_for_mobile: "15px",
  black: "_black",
  get_in_touch: "Get in touch",
  support_portal: "Support Portal",
  support_portal_link: "https://olan.screenconnect.com/",
  menu: "Menu",
  home: "Home",
  about_us: "About Us",
  our_work: "Services",
  clients: "Clients",
  our_blog: "News and resources",
  contact_us: "Contact Us",
  manage_it_service: "Managed IT Services",
  enterprise_network_management: "Enterprise Network Management",
  it_consulting_services: "IT Consulting Services",
  staff_augmentation_services: "Staff Augmentation Services",
  slide_track: "slide-track",
  wrapper_id: "#wrapper",
  name: "Name",
  email: "Email",
  mobile_number: "Mobile Number",
  subject: "Subject",
  send_message: "Send Message",
  submit: "Submit",
  enterpriseVIOBusiness_service_link: "https://easylinksolutions.com/",
  enterpriseVIOBusiness_service_name: "Enterprise VoIP Business Phone Solution",
  enterpriseVIOBusiness_service_desc: "Enterprise VoIP Business Phone Solution",
  blog_1: "Blog 1",
  blog_2: "Blog 2",
  blog_3: "Blog 3",
  blog_4: "Blog 4",
};
const SocialLink = {
  call_1: "(202) 255-9046",
  call_2: "(888) 879-7056",
  mail_1: "info@olanassociates.com",
  mail_2: "support@olanassociates.com",
  call_1_link: "tel:+1 202-255-9046",
  call_2_link: "tel:+1 888-879-7056",
  mail_1_link: "mailto:info@olanassociates.com",
  mail_2_link: "mailto:support@olanassociates.com",
  facebook_link: "https://www.facebook.com/olanassociates/",
  twitter_link: "https://twitter.com/olanassociates/",
  linkedin_link: "https://www.linkedin.com/company/olan-associates/",
  location_1: "405 Park Avenue, Baltimore, MD 21201",
  location_1_link: "https://goo.gl/maps/PUYkrCmujibgNE3x8",
};
const ContactUsCard = {
  title_1: "Mail Us",
  sub_title_1: "Drop a line",
  title_2: "24/7 Service",
  sub_title_2: "Call Us",
  title_3: "Corporate HQ,",
  sub_title_3: "Visit Us",
};
const ServiceList = {
  staff_augmentation_services: "Staff Augmentation Services",
  staff_augmentation_services_desc:
    "Our talent acquisition strategy equipped with eagle eyes and laser-edge focus delivers the best-fit talent at a reasonable cost",
  managed_IT_services: "Managed IT Services",
  managed_IT_services_desc:
    "Supporting and safeguarding your IT environment in the background",
  IT_consulting_services: "IT Consulting Services",
  IT_consulting_services_desc:
    "Integrate technology into your business and improve performance, market size, and competitiveness",
  enterprise_network_management: "Enterprise Network Management",
  enterprise_network_management_desc:
    "Secure your network with 24/7 monitoring and proactive support services",
};
const Partner = {
  title_1:
    "OUR GOAL IS TO ENABLE A WORLD IN WHICH IMPORTANT DATA IS ALWAYS PROTECTED, AVAILABLE AND USEFUL.",
  title_1_desc:
    "Our business may be in the cloud, but we're grounded by the belief that what we do matters. Read on to find out more about Carbonite and the people who help us protect what matters and keep businesses in business.",
  title_1_link: "https://www.carbonite.com/",
  title_2:
    "SMART IS CHANGING THE WORLD BY CHANGING THE WAY KNOWLEDGE CAN BE SHARED.",
  title_2_desc:
    "Our software and hardware solutions empower everyone — from primary school students to innovators in Fortune 500 companies — to come together to explore and expand on ideas, bringing them to life without geographical or technological limitations.",
  title_2_link: "https://www.smarttech.com/en/education",
  title_3:
    "PRACTICE FUSION’S EHR: THE MOST PROGRESSIVE TOOL FOR DOCTORS AND PATIENTS.",
  title_3_desc:
    "Practice Fusion is the #1 cloud-based ambulatory EHR platform in the U.S.,1 supporting 30,000 medical practices in delivering better care to 5 million patients a month. With a best-in-class satisfaction rate,2 Practice Fusion is committed to delivering intuitive and easy-to-use health IT solutions to small, independent medical practices.",
  title_3_link: "https://practicefusion.com/",
  title_4:
    "OUR GOAL IS TO ENABLE A WORLD IN WHICH IMPORTANT DATA IS ALWAYS PROTECTED, AVAILABLE AND USEFUL.",
  title_4_desc:
    "Our business may be in the cloud, but we're grounded by the belief that what we do matters. Read on to find out more about Carbonite and the people who help us protect what matters and keep businesses in business.",
  title_4_link: "https://partner.microsoft.com/en-US/",
};
const WhyChooseUs = {
  dedication: "Dedication",
  dedication_desc:
    "We go the extra mile and deliver value at every stage to ensure that our customers receive the best service",
  efficient: "Efficient",
  efficient_desc:
    "A proactive approach towards the services we provide for our customers improves efficiency from initiation through delivery and during operation",
  customer_satisfaction: "Customer Satisfaction",
  customer_satisfaction_desc:
    "Our efforts toward fast response and resolution make us the go-to solution provided for our client’s business needs",
  teamwork: "Teamwork",
  teamwork_desc:
    "Together with adding value to oneself, our people focus on adding value to others, in turn strengthening the team in terms of skills and morale. This extends beyond our team to our clients",
};
const EnterpriseServices = {
  server: "Server",
  advanced_performance_monitoring: "Advanced Performance Monitoring",
  key_application_maintenance: "Key Application Maintenance",
  os_patch_management: "OS Patch Management",
  scheduled_preventative_maintenance: "Scheduled Preventative Maintenance",
  availability_monitoring: "Availability Monitoring",
  workstation: "Workstation",
  backup_deployment_configuration: "Backup Deployment & Configuration",
  backup_performance_monitoring: "Backup Performance Monitoring",
  backup_software_updates: "Backup Software Updates",
  scheduled_backup_jobs: "Scheduled Backup Jobs",
  proactive_support: "Proactive Support",
  quarterly_network_health_review: "Quarterly Network Health Review",
  additional_network:
    "Additional Network, End-user & Network Support Billed at T&M Rates",
  proactive_security: "Proactive Security",
  anti_virus_monitoring: "Anti-Virus Monitoring & Management",
  anti_spam_monitoring: "Anti-Spam Monitoring & Management",
  network: "Network",
  router_switch_monitoring: "Router/Switch Monitoring",
};
const ITConsulting = {
  custom_software_development: "Custom Software Development",
  custom_software_development_desc:
    "Enabling you to create Software solutions specifically befitting your business requirements.",
  website_design_and_redesign: "Website Design And Redesign",
  website_design_and_redesign_desc:
    "Redefine web experience with designs focused on accessibility, user experience, performance, and responsiveness",
  web_application_development: "Web Application Development",
  web_application_development_desc:
    "Empower your web-based projects and deliver services to your users at every corner of the world through the internet",
  phone_application_development: "Phone Application Development",
  phone_application_development_desc:
    "Deliver your business to your customers at the palm of their hand. Draw your customer’s interest with user experience like never before",
  maintenance_and_support: "Maintenance And Support",
  maintenance_and_support_desc:
    "Continued support and maintenance after delivery, ensuring optimum performance and little to zero downtime",
  accessibility_and_multilingual: "Accessibility And Multilingual",
  accessibility_and_multilingual_desc:
    "No more barriers between you and your customers",
};

const StaffServices = {
  contractual_talent_acquisition: "Contractual Talent Acquisition",
  contractual_talent_acquisition_desc:
    "Tackle your short-term recurring talent needs with our Contractual Talent Acquisition services",
  contractual_to_perm_talent_acquisition:
    "Contractual–to–Perm Talent Acquisition",
  contractual_to_perm_talent_acquisition_desc:
    "Need time to think about hiring due to uncertainty and budgetary concerns? Work with our consultants for a pre-determined term and hire them full-time when they match or exceed your needs.",
  permanent_talent_acquisition: "Permanent Talent Acquisition",
  permanent_talent_acquisition_desc:
    "Boost your productivity with skillful and reliable talents for crucial full-time permanent roles",
};

const ITservices = {
  it_support_essentials: "IT Support Essentials",
  it_support_essentials_desc:
    "Having trouble keeping track of your IT assets? Wish you had a better way to get faster support. This is the answer for you.",
  professional_monitoring: "Professional Monitoring",
  professional_monitoring_desc:
    "There is no better way to know what is going on in your IT environment and therefore, no better way to speed up recovery when an issue occurs.",
  patch_management: "Patch Management",
  patch_management_desc:
    "Never worry about having your patches up to date again. Let the experts ensure that your windows and non-windows patches are at a secure level.",
  anti_virus: "Anti-virus",
  anti_virus_desc:
    "Total desktop security solution including Anti-Virus, Anti-Malware, and Hosted Intrusion Protection.",
  anti_spam: "Anti-spam",
  anti_spam_desc:
    "80% of all viruses are delivered via email. This service offers perimeter protection preventing unwanted email and spam from reaching your network or users.",
  backup: "Backup",
  backup_desc:
    "Ensure business continuity through scheduled back-up of critical data and restoration in the event of data loss.",
  replication: "Replication",
  replication_desc:
    "Moving your data offsite daily provides an additional layer of protection in the event of a disaster. Critical data and restore information are housed in our secure data center.",
  compliance_reporting: "Compliance Reporting",
  compliance_reporting_desc:
    "Meet regulatory compliance requirements for less than your phone bill each month. Being prepared for the audit is much cheaper than paying for it.",
};
export {
  EnterpriseServices,
  CommonString,
  SocialLink,
  ContactUsCard,
  ServiceList,
  Partner,
  WhyChooseUs,
  ITservices,
  StaffServices,
  ITConsulting,
};
