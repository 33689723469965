import { Icon } from "@iconify/react";
import { Divider } from "@material-ui/core";
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeIsOpenMenu, changeMenuStatus } from "../../redux/HomeReduxStore";
import {
  Div,
  HeaderContainer,
  MenuIconForMobile,
  SolidButton,
  SolidButtonForNavbar,
} from "../../style/common.style";
import {
  MenuContainerForMobile,
  MenuItem,
  MenuItemContainer,
  RedirectLinkIcon,
  SmallText,
  StickyIcon,
  StickyIconContainerForMobile,
} from "../../style/header.style";
import { LinkContainer } from "../../style/home.style";
import Images from "../../utility/common.image";
import { FooterStickyList, MenuItemList } from "../../utility/common.mockdata";
import { CommonString } from "../../utility/constant";
import { isActive } from "../../utility/function";
import { CommonIcon } from "../../utility/icon";
import MobileViewHeader from "./MobileViewHeader";

export default function CommonHeader() {
  // const [isOpenMenu, setisOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const menuStatus = useSelector((state) => state.HomeReduxStore.menuStatus);
  const isOpenMenu = useSelector((state) => state.HomeReduxStore.isOpenMenu);
  return (
    <>
      <HeaderContainer>
        <img
          onClick={() => {
            dispatch(changeMenuStatus(CommonString.home));
            window.scrollTo(0, 0);
          }}
          alt="logo"
          src={Images.horizontal_logo}
          className="logo-icon"
        />
        <MenuIconForMobile
          onClick={() => dispatch(changeIsOpenMenu(!isOpenMenu))}
          icon={isOpenMenu ? CommonIcon.close : CommonIcon.menu}
          height={30}
        />
        <MenuItemContainer>
          {MenuItemList.map((singleData) => (
            <MenuItem
              isDisplayNone={singleData === CommonString.contact_us}
              isActive={singleData === isActive(menuStatus)}
              key={singleData}
              onClick={() => {
                dispatch(changeMenuStatus(singleData));
                window.scrollTo(0, 0);
              }}
            >
              {singleData}
            </MenuItem>
          ))}
          <LinkContainer
            href={CommonString.support_portal_link}
            target={CommonString.black}
          >
            <MenuItem>
              {CommonString.support_portal}
              <RedirectLinkIcon icon={CommonIcon.redirect} />
            </MenuItem>
          </LinkContainer>
          <SolidButtonForNavbar
            onClick={() => dispatch(changeMenuStatus(CommonString.contact_us))}
          >
            {CommonString.contact_us}
          </SolidButtonForNavbar>
          {/* <Nav.Link href="#home" className="nav-button">
            Home
          </Nav.Link>
          <Nav.Link href="#features" className="nav-button">
            About Us
          </Nav.Link>
          <Nav.Link
            href="#pricing"
            className="nav-button"
            style={{ marginRight: 110 }}
          >
            Services
          </Nav.Link> */}

          {/* <Nav.Link href="#pricing" className="nav-button-icon">
            <Icon icon="ion:mail-outline" height={16} />
          </Nav.Link> */}
        </MenuItemContainer>
      </HeaderContainer>
      {isOpenMenu ? (
        <MenuContainerForMobile>
          <SmallText>{CommonString.menu}</SmallText>
          {MenuItemList.map((singleData) => (
            <MenuItem
              isActive={singleData === isActive(menuStatus)}
              key={singleData}
              onClick={() => {
                dispatch(changeMenuStatus(singleData));
                dispatch(changeIsOpenMenu(false));
                window.scrollTo(0, 0);
              }}
            >
              {singleData}
            </MenuItem>
          ))}

          <LinkContainer
            href={CommonString.support_portal_link}
            target={CommonString.black}
          >
            <MenuItem>
              {CommonString.support_portal}
              <RedirectLinkIcon icon={CommonIcon.redirect} />
            </MenuItem>
          </LinkContainer>
          <Divider />
          <SmallText>{CommonString.get_in_touch}</SmallText>
          <StickyIconContainerForMobile>
            {FooterStickyList.map((data) => (
              <LinkContainer href={data.link} target={CommonString.black}>
                <StickyIcon icon={data.icon} />
              </LinkContainer>
            ))}
          </StickyIconContainerForMobile>
        </MenuContainerForMobile>
      ) : (
        <Div />
      )}
    </>
  );
}
