import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../utility/color";
import Images from "../utility/common.image";
import { CommonString } from "../utility/constant";

const AboutUsContainer = styled.div`
  padding: ${CommonString.common_padding};
  @media (${CommonString.mobile_screen}) {
    padding: ${CommonString.common_padding_for_mobile};
  }
`;
const AboutUsImg = styled.img`
  // width: -webkit-fill-available;
  // height: 100vh;
  width: 100%;
  @media (${CommonString.mobile_screen}) {
    height: auto;
    margin-top: 63px;
    width: 100%;
  }
`;
const AboutUsImgContainer = styled.div`
height: 50vh;
margin-top: 30px;
border-radius: 20px;
background-color: rgb(214, 214, 214);
background-image: url(${Images.aboutUsBackgroundImg});
background-size: cover;
background-repeat: no-repeat;
display: flex;
-webkit-box-align: center;
align-items: center;
text-align: center;
margin:20px;
margin-top: 80px;
flex-direction:column;
    justify-content: center;
    padding:10px;
}
  @media (${CommonString.mobile_screen}) {
    height: auto;
    // border-radius: 20px;
    padding:20px;
  }
`;
//partner
const PartnerContainer = styled.div`
  margin: -10px;
`;
const PartnerCardContainer = styled.div`
  background: ${CommonColor.white};
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  transition: 0.6s;
  border: 1px solid ${CommonColor.light_grey};
  &:hover {
    border: 1px solid ${CommonColor.secondary_color};
    background: ${CommonColor.secondary_background_color};
  }
  &:hover svg {
    color: ${CommonColor.primary_color};
    transition: 0.6s;
    margin-left:20px;
    // rotate: -45deg;
  }
  @media (${CommonString.mobile_screen}) {
    &:hover {
      background: ${CommonColor.white};
    }
  }
`;
const PartnerImageContainer = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
const PartnerImage = styled.img`
  width: auto;
`;
const PartnerTitle = styled.p`
  color: ${CommonColor.secondary_color};
  text-transform: capitalize;
`;
const PartnerDesc = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
`;
const PartnerViewMoreButton = styled.p`
  color: ${CommonColor.primary_color};
  margin-bottom: 0;
  transition: 0.2s;
`;
const PartnerViewMoreIcon = styled(Icon)`
  color: ${CommonColor.grey};
  font-size: 20px;
`;
//core value
const CoreValueContainer = styled.div`
  color: ${CommonColor.white};
  font-size: 20px;
  background: ${CommonColor.secondary_background_color};
  padding: 20px;
  padding-top: 40px;
  margin-bottom: 20px;
  // border-radius: 40px;
  // margin: 20px -20px;
`;
const CoreValueCardContainer = styled.div`
  color: ${CommonColor.white};
  border: 1px solid ${CommonColor.border};
  padding: 15px;
  margin: ${(props) => (props.margin ? "-20px 20px" : "20px")};
  // border-radius: 15px;
  margin: ${(props) => (props.margin ? "-20px 20px" : "20px")};
  min-height: 140px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  &:hover {
    border: 1px solid ${CommonColor.grey};
  }
  @media (${CommonString.mobile_screen}) {
    margin: 15px 0;
  }
`;
const CoreValueCardTitle = styled.h5`
  color: ${CommonColor.secondary_color};
  font-weight: bold;
`;
const CoreValueCardContent = styled.p`
  margin-bottom: 0;
  margin-top: 10px;
  color: ${CommonColor.primary_color};
`;
const ValueCardContainer = styled.div`
  display: flex;
`;
const AboutMainContainer = styled.div`
  overflow: hidden;
`;
export {
  AboutUsImgContainer,
  AboutMainContainer,
  AboutUsImg,
  ValueCardContainer,
  CoreValueCardTitle,
  CoreValueCardContent,
  CoreValueCardContainer,
  CoreValueContainer,
  PartnerViewMoreIcon,
  PartnerImageContainer,
  PartnerViewMoreButton,
  PartnerDesc,
  PartnerTitle,
  AboutUsContainer,
  PartnerCardContainer,
  PartnerContainer,
  PartnerImage,
};
