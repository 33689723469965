import { Grid } from "@material-ui/core";
import React from "react";
import {
  BlogMainContainer,
  BlogMainImg,
  BlogSectionNew,
  BlogSubTitle,
  BlogText,
  BlogTextContainer,
  BlogTextMargin,
} from "../../style/blog.style";
import { Span, TitleContainer } from "../../style/common.style";
import { Title } from "../../style/home.style";
import { CommonColor } from "../../utility/color";
import Images from "../../utility/common.image";
import BlogSection from "../HomeScreen/BlogSection";

export default function Blog2() {
  return (
    <BlogMainContainer>
      <Grid container>
        <Grid item md={4} xs={12}>
          <BlogMainImg src={Images.blog_2} />
        </Grid>
        <Grid item md={8} xs={12}>
          <BlogSectionNew>
            <BlogSubTitle>
              Using Blockchain Technology to Boost Cyber Security
            </BlogSubTitle>
            <BlogText>
              Navigating the online world safely has become a real concern over
              the last few years and, looking at how intense and sophisticated
              some of the recent hacker attacks around the world have been, it
              seems like things are bound to only get worse.
            </BlogText>
            <BlogText>
              Even though hackers are getting better at hacking, the ways to
              combat them are also improving very fast. In fact, we already have
              a nearly impenetrable technology, known as blockchain, which can
              be used to protect our data from cyber attacks and improve
              cybersecurity across industries.
            </BlogText>
            <BlogText>
              This article provides an overview of how blockchains can improve
              the online security of any business, ensuring that data cannot be
              damaged, stolen, or lost.
            </BlogText>
            <BlogSubTitle>Blockchain technology 101</BlogSubTitle>
            <BlogText>
              Blockchain technology has been around for just under a decade,
              initially introduced as a way to store and/or send the first
              cryptocurrency, Bitcoin. However, as the technology has gradually
              spread worldwide, people have begun using it in a variety of ways
              in numerous industries, including as a means to increase
              cybersecurity.
            </BlogText>
          </BlogSectionNew>
        </Grid>
      </Grid>
      <BlogSubTitle>What is a blockchain?</BlogSubTitle>
      <BlogText>
        Blockchains are distributed networks that can have millions of users all
        over the world. Every user can add information to the blockchain and all
        data in the blockchain is secured through cryptography. Every other
        member of the network is responsible for verifying that the data being
        added to the blockchain is real. This is done using a system of three
        keys (private, public, and the receiver’s key) that allow members to
        check the veracity of the data while also confirming who it comes from.
      </BlogText>
      <BlogSubTitle>How do blockchains get formed?</BlogSubTitle>
      <BlogText>
        A verified piece of data forms a block which then has to be added to the
        chain. To do this, blockchain users have to use their respective keys
        and powerful computing systems to run algorithms that solve very complex
        mathematical problems. When a problem is solved, the block is added to
        the chain and the data it contains exists on the network forever,
        meaning that it cannot be altered or removed.
      </BlogText>
      <BlogSubTitle>How can data be updated?</BlogSubTitle>

      <BlogText>
        In order to make updates to a particular piece of data, the owner of
        that data must add a new block on top of the previous block, creating a
        very specific chain of code. If anything, even something as small as a
        comma, gets altered from how it appears in a previous block, the entire
        chain across the network also changes accordingly. This means that every
        single alteration or change to any piece of data is tracked and
        absolutely no data is lost or deleted because users can always look at
        previous versions of a block to identify what is different in the latest
        version. Using this thorough form of record-keeping makes it easy for
        the system to detect blocks that have incorrect or false data,
        preventing loss, damage, and corruption.
      </BlogText>
      <BlogSubTitle>
        Decentralized storage, recordkeeping, and peer-to-peer sharing
      </BlogSubTitle>

      <BlogText>
        Another important thing to note about blockchain users is that they are
        able to store all of the data in their network on their computer, if
        they want to (and very many of them do). This results in two things.
        First, they can earn money for renting their “extra” storage space and,
        second, they ensure that the chain will not collapse. If, for instance,
        someone who is not the owner of a piece of data (say, a hacker) tries to
        tamper with a block, the whole system analyzes every single block of
        data to find the one that differs from the rest (or from the majority).
        If the system finds this type of block, it simply excludes it from the
        chain, identifying it as false.
      </BlogText>
      <BlogText>
        Blockchain technology is designed in such a way that there is no central
        authority or storage location. Every user on the network plays a part in
        storing some or all of the blockchain. Everyone is responsible for
        verifying the data that is stored and/or shared to make sure false data
        cannot be added and existing data cannot be removed.
      </BlogText>
      <BlogSubTitle>Preventing fraud and data theft</BlogSubTitle>

      <BlogText>
        Blockchain technology provides one of the best tools we currently have
        to protect data from hackers, preventing potential fraud and decreasing
        the chance of data being stolen or compromised.
      </BlogText>
      <BlogText>
        In order to destroy or corrupt a blockchain, a hacker would have to
        destroy the data stored on every user’s computer in the global network.
        This could be millions of computers, with each one storing a copy of
        some or all the data. Unless the hacker could simultaneously bring down
        an entire network (which is near impossible), undamaged computers, also
        known as “nodes”, would continue running to verify and keep record of
        all the data on the network. The impossibility of a task like taking
        down a whole chain increases along with the amount of users on a
        network. Bigger blockchain networks with more users have an infinitely
        lower risk of getting attacked by hackers because of the complexity
        required to penetrate such a network.
      </BlogText>
      <BlogText>
        This complex structure provides blockchain technology with the ability
        to be the most secure form of storing and sharing information online
        that we’ve discovered so far. That’s why innovators have begun applying
        the technology in different sectors to prevent fraud and increase
        protection of data.
      </BlogText>
      <BlogSubTitle>
        How Guardtime uses blockchain technology to safeguard data
      </BlogSubTitle>
      <BlogText>
        Guardtime has already become successful in using blockchain technology
        to keep important data safe.
      </BlogText>
      <BlogText>
        The company takes away the need to use keys for verification. Instead,
        they distribute every piece of data to nodes throughout the system. If
        someone tries to alter the data, the system analyses the whole mass of
        chains, compares them to the metadata packet and then excludes any that
        don’t match up.
      </BlogText>
      <BlogText>
        This means that the only way to wipe the entire blockchain out is to
        destroy every single separate node. If just one node remains running
        with the correct data, the whole system can be restored, even if all of
        the other nodes are compromised.
      </BlogText>
      <BlogText>
        Guardtime’s system works in such a way that it’s always able to detect
        when a change has been made to the data and is constantly verifying the
        changes. This ensures that there is no discrete way to tamper with
        blocks in the chain and the data remains uncompromised.
      </BlogText>
      <BlogSubTitle>
        Preventing Distributed Denial of Service (DDoS) attacks
      </BlogSubTitle>
      <BlogText>
        The principle behind DDoS attacks is simple but devastating. Hackers can
        use several techniques to instigate an attack, essentially sending
        myriads of junk requests to a website, increasing traffic until the site
        can no longer keep up with the requests. The attack goes on until the
        site gets overwhelmed with requests and crashes. DDoS attacks have been
        happening at an increased frequency recently, affecting bigger companies
        like Twitter, Spotify, SoundCloud, and more.
      </BlogText>
      <BlogText>
        The current difficulty in preventing DDoS attacks comes from the
        existing Domain Name System (DNS). DNS is a partially decentralized
        one-to-one mapping of IP addresses to domain names and works much like a
        phone book for the Internet. This system is responsible for resolving
        human-readable domain names (like steelkiwi.com) into machine-readable
        IP addresses (made up of numbers).
      </BlogText>
      <BlogText>
        The fact that it is only partially decentralized means that it is still
        vulnerable to hackers because they are able to target the centralized
        part of DNS (the one which stores the main bulk of data) and continue
        crashing one website after another.
      </BlogText>
      <BlogSubTitle>Using blockchains to prevent DDoS attacks</BlogSubTitle>
      <BlogText>
        Implementing blockchain technology would fully decentralize DNS,
        distributing the contents to a large number of nodes and making it
        nearly impossible for hackers to attack. Domain editing rights would
        only be granted to those who need them (domain owners) and no other user
        could make changes, significantly reducing the risk of data being
        accessed or changed by unauthorized parties. By using blockchains to
        protect the data, a system can ensure that it’s invulnerable to hackers,
        unless every single node is simultaneously wiped clean.
      </BlogText>
      <BlogText>
        Some companies are already implementing blockchain in this area to
        prevent DDoS attacks from occurring. For instance, Blockstack provides a
        fully decentralized option for DNS. The concept behind the company is to
        make the entire worldwide web decentralized by removing all third
        parties from managing web servers, ID systems, and databases.
      </BlogText>
      <BlogText>
        If current DNS would operate on blockchain, users would still be able to
        register domain names, but only authorized owners would be able to make
        changes to their domains. Since the data would be stored on many
        different nodes and every other user on the network would have a copy of
        the entire data on the blockchain, it would be virtually impossible to
        hack or destroy it completely.
      </BlogText>
      <BlogText>
        MaidSafe is a similar company based in the UK. Their goal is also to
        decentralize the web and create something like an alternative Internet
        where users are able to run apps, store data, and do everything else
        they normally do online, but in a more secure environment. When signing
        up for this service, users can choose how much of their personal storage
        space they want to dedicate to the network. The system then provides
        safecoin, a cryptocurrency, to compensate users for the value (space)
        they offer the network. Every file placed onto the MaidSafe network is
        encrypted, fragmented, and shared among users. The only person that can
        make the data readable again is its owner, ensuring that the data is not
        accessible by anyone other than the authorized owner.
      </BlogText>
      <BlogSection />
    </BlogMainContainer>
  );
}
