import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  BottomFooterContainer,
  BottomFooterLogo,
  BottomFooterText,
  CopyRightIcon,
  FooterContainer,
  FooterDesc,
  FooterImg,
  FooterImgContainer,
  FooterLogo,
  FooterText,
  FooterTextArrow,
  FooterTitle,
} from "../style/footer.style";
import { useDispatch, useSelector } from "react-redux";
import { CommonString } from "../utility/constant";
import { changeMenuStatus } from "../redux/HomeReduxStore";
import { CommonIcon } from "../utility/icon";
import $ from "jquery";
import img_1 from "../assets/images/img_1.png";
import img_2 from "../assets/images/img_2.png";
import { MenuItemList, Service } from "../utility/common.mockdata";
import { isActiveForFooter } from "../utility/function";
import bottom_logo from "../assets/images/bottom_logo.png";
import { LinkContainer } from "../style/home.style";
import Images from "../utility/common.image";
import { RedirectLinkIcon } from "../style/header.style";
import { CommonColor } from "../utility/color";
export default function Footer() {
  const dispatch = useDispatch();
  const menuStatus = useSelector((state) => state.HomeReduxStore.menuStatus);
  const isOpenMenu = useSelector((state) => state.HomeReduxStore.isOpenMenu);
  function onClickOnFooterMenu(data) {
    window.scrollTo(0, 0);
    setTimeout(() => {
      dispatch(changeMenuStatus(data));
    }, 500);
  }
  return (
    <>
      <FooterContainer isOpenMenu={isOpenMenu}>
        <Grid container>
          <Grid item md={4} xs={12}>
            <FooterLogo alt="logo" src={Images.horizontal_logo} />
            <FooterDesc>
              Pre - Eminent Managed Services Provider, your one-stop global IT
              Management and Consulting Firm
            </FooterDesc>
            <FooterImgContainer>
              <FooterImg src={img_1} />
              <FooterImg width="150px" src={img_2} />
            </FooterImgContainer>
          </Grid>
          <Grid item md={1} xs={12}></Grid>
          <Grid item md={3} xs={12}>
            <FooterTitle>Company</FooterTitle>
            {MenuItemList.map((data) => (
              <FooterText
                isActive={data === isActiveForFooter(menuStatus)}
                onClick={() => onClickOnFooterMenu(data)}
              >
                <FooterTextArrow icon={CommonIcon.arrow} />
                {data}
              </FooterText>
            ))}
            <LinkContainer
              href={CommonString.support_portal_link}
              target={CommonString.black}
            >
              <FooterText
                isActive={
                  CommonString.support_portal === isActiveForFooter(menuStatus)
                }
                onClick={() => onClickOnFooterMenu(CommonString.support_portal)}
              >
                <FooterTextArrow icon={CommonIcon.arrow} />
                {CommonString.support_portal}
                <RedirectLinkIcon icon={CommonIcon.redirect} />
              </FooterText>
            </LinkContainer>
          </Grid>
          <Grid item md={4} xs={12}>
            <FooterTitle>Services</FooterTitle>
            {Service.map((data, index) => (
              <FooterText
                // isNotVisible={index === 0}
                onClick={() => onClickOnFooterMenu(data.menu_status)}
                isActive={data.menu_status === isActiveForFooter(menuStatus)}
              >
                <FooterTextArrow icon={CommonIcon.arrow} />
                {data.service_name}
              </FooterText>
            ))}
            <LinkContainer
              color={CommonColor.white}
              noChange={true}
              href={CommonString.enterpriseVIOBusiness_service_link}
              target={CommonString.black}
            >
              <FooterText>
                <FooterTextArrow icon={CommonIcon.arrow} />
                {CommonString.enterpriseVIOBusiness_service_name}
              </FooterText>
            </LinkContainer>
          </Grid>
        </Grid>
      </FooterContainer>
      <BottomFooterContainer>
        <BottomFooterText>
          <CopyRightIcon icon={CommonIcon.copyright} />
          OLAN ASSOCIATES ©2022
        </BottomFooterText>
        <BottomFooterLogo
          onClick={() => window.scrollTo(0, 0)}
          src={bottom_logo}
        />
      </BottomFooterContainer>
    </>
  );
}
